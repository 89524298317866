import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';

import { NavigateFunction } from 'react-router-dom';
import { generateStateString } from '../../../utils/auth';
import { CustomNavigationClient } from './NavigationClient';
import {
  loginRequest,
  msalConfig,
  onedriveRequest,
  sharepointRequest,
} from './authConfig';

export class Microsoft {
  public static loginRedirect(
    instance: IPublicClientApplication,
    navigate: NavigateFunction,
  ) {
    instance.setNavigationClient(new CustomNavigationClient(navigate));

    loginRequest.state = generateStateString();

    instance.loginRedirect(loginRequest);
  }

  public static authOnedriveRedirect(
    instance: IPublicClientApplication,
    navigate: NavigateFunction,
  ) {
    instance.setNavigationClient(new CustomNavigationClient(navigate));

    instance.acquireTokenRedirect(onedriveRequest);
    // instance.loginRedirect(onedriveRequest);
  }

  public static authSharepointRedirect(
    instance: IPublicClientApplication,
    navigate: NavigateFunction,
  ) {
    instance.setNavigationClient(new CustomNavigationClient(navigate));
    instance.acquireTokenRedirect(sharepointRequest);

    // instance.loginRedirect(sharepointRequest);
  }

  public static initialize(clientId?: string): IPublicClientApplication {
    msalConfig.auth.redirectUri = `${window.location.origin}/auth/microsoft/callback`;
    msalConfig.auth.clientId = clientId ?? msalConfig.auth.clientId;

    return new PublicClientApplication(msalConfig);
  }
}
