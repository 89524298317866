import {
  BrowserCacheLocation,
  Configuration,
  PopupRequest,
} from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: '<client_id>',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '<redirect_uri>',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    temporaryCacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {},
  },
};

export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
  prompt: 'select_account',
};

export const onedriveRequest: PopupRequest = {
  scopes: [
    'Files.ReadWrite.All',
    'offline_access',
    'User.Read',
    'openid',
    'profile',
    'email',
  ],
  prompt: 'select_account',
  redirectUri: `${window.location.origin}/auth/integration/microsoft/callback`,
};

export const sharepointRequest: PopupRequest = {
  scopes: [
    'Files.ReadWrite.All',
    'offline_access',
    'User.Read',
    'openid',
    'profile',
    'email',
    'Sites.ReadWrite.All',
  ],
  prompt: 'select_account',
  redirectUri: `${window.location.origin}/auth/integration/sharepoint/callback`,
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
