import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { useTranslation } from 'react-i18next';
import { useIntegrationsStore } from '../store';
import { StyledContent, StyledDialog } from './connect-confirm.styled';

const ConnectConfirm = () => {
  const { t } = useTranslation('integrations');
  const { isConnectConfirmOpen, cancelConnect, confirmConnect } =
    useIntegrationsStore();

  return (
    <StyledDialog
      open={isConnectConfirmOpen}
      onClose={cancelConnect}
      aria-labelledby="connect-confirm"
      aria-describedby="connect-confirm"
      data-cy="connect-confirm"
    >
      <ModalCloseButton
        data-cy="connect-confirm-close"
        aria-label="close"
        onClick={cancelConnect}
      >
        <FontAwesomeIcon icon={regular('close')} />
      </ModalCloseButton>
      <DialogTitle id="alert-dialog-title">
        {t('sharepoint.confirm.title')}
      </DialogTitle>
      <DialogContent dividers>
        <StyledContent>
          <p>{t('sharepoint.confirm.subtitle')}</p>
          <ol>
            <li>{t('sharepoint.confirm.desc_1')}</li>
            <li>{t('sharepoint.confirm.desc_2')}</li>
          </ol>
          <p>{t('sharepoint.confirm.footer')}</p>
        </StyledContent>
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="connect-confirm-cancel"
          onClick={cancelConnect}
          color="tertiary"
          variant="outlined"
        >
          {t('sharepoint.confirm.cancel_btn')}
        </Button>
        <Button
          data-cy="connect-confirm-btn"
          onClick={confirmConnect}
          color="primary"
          variant="contained"
        >
          {t('sharepoint.confirm.confirm_btn')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ConnectConfirm;
