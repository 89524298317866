import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionsKeys } from '@gen2/types/permissions';
import { Permissions } from '@gen2/utils/permissions/permissions';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useInviteListingStore } from '../../store';

export const Actions = ({ id, subject }: { id: string; subject: string }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setArchive } = useInviteListingStore();

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleArchive = () => {
    setArchive({ isOpen: true, subject, id });
    handleOnClose();
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/invite-listing/${id}`,
    );
    handleOnClose();
  };

  const handleViewInvite = () => {
    window.open(`/invite-listing/${id}`, '_blank');
    handleOnClose();
  };

  const handleEditInvite = () => {
    window.open(`/send-invites/${id}`, '_blank');
    handleOnClose();
  };

  return (
    <div>
      <IconButton data-cy="ellipsis-button" onClick={handleClick}>
        <FontAwesomeIcon icon={regular('ellipsis-vertical')} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleOnClose}
      >
        <MenuItem onClick={handleCopyToClipboard}>Copy Invite Link</MenuItem>
        <MenuItem onClick={handleViewInvite}>View</MenuItem>
        <Permissions allow={PermissionsKeys.UPDATE_INVITE}>
          <MenuItem onClick={handleEditInvite}>Edit Invite</MenuItem>
        </Permissions>
        <Permissions allow={PermissionsKeys.ARCHIVE_INVITE}>
          <MenuItem onClick={handleArchive}>Archive Invite</MenuItem>
        </Permissions>
      </Menu>
    </div>
  );
};
