import { colors, weights } from '@components/theme/gen2';
import { Button, Dialog, ListItemButton, styled } from '@mui/material';
import { rem } from 'polished';

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: rem(550),
    margin: 0,
  },
});

export const StyledDisabledListItemButton = styled(ListItemButton)({
  color: `${colors.grayDark3} !important`,
  opacity: `1 !important`,
});

export const StyledFinderLabel = styled('p')({
  display: 'flex',
  marginBottom: rem(20),

  '& > span': {
    fontWeight: weights.semiBold,
    marginLeft: rem(5),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export const StyledNewFolderButton = styled(Button)({
  marginTop: rem(20),
});

export const StyledFinderFooter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
