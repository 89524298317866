import { colors, textSizes, weights } from '@components/theme/gen2';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  ListItem,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { rem } from 'polished';
import { NavLink } from 'react-router-dom';

export const StyledIntegrations = styled('div')({
  minHeight: 'calc(100vh - var(--header-height))',
  backgroundColor: colors.grayLight2,
});

export const StyledContent = styled('div')({
  paddingTop: rem(15),
  maxWidth: rem(1280),
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: rem(30),
  paddingRight: rem(30),
});

export const StyledTitle = styled('h5')({
  fontSize: textSizes.xl,
  fontWeight: weights.semiBold,
  margin: 0,
  marginBottom: rem(15),
});

export const StyledIntCard = styled(NavLink)(({ theme }) => ({
  display: 'inline-block',
  width: '100%',
  borderRadius: rem(10),
  overflow: 'hidden',
  backgroundColor: colors.grayLight3,
  maxWidth: rem(266),
  border: `2px solid ${colors.grayLight2}`,
  cursor: 'pointer',
  color: colors.grayDark3,
  textDecoration: 'none',
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      border: `2px solid ${colors.secondary}`,
    },
  },
}));

export const StyledIntCardHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: rem(90),
  padding: `${rem(20)} ${rem(32)}`,
  backgroundColor: colors.white,
});

export const StyledIntCardImg = styled('img')({
  width: '100%',
  objectFit: 'contain',
});

export const StyledIntCardContent = styled('div')({
  padding: `${rem(20)} ${rem(15)}`,
  display: 'flex',
  flexDirection: 'column',
  gap: rem(15),
});

export const StyledListItem = styled(ListItem)({
  '& .MuiListItemText-primary': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export const StyledIntCards = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  gap: rem(30),
  marginBottom: rem(30),
});

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: rem(375),
  },
});

export const StyledButton = styled(LoadingButton)(({ variant }) => ({
  width: 'fit-content',
  display: 'flex',
  gap: rem(4),
  minWidth: rem(64),
  padding: `${rem(4)} ${rem(8)}`,
  ...(variant === 'outlined' && {
    borderColor: colors.secondary,
  }),
}));

export const StyledIntCardLevel = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: rem(32),
  height: rem(32),
  borderRadius: '50%',
  border: `1px solid ${colors.grayDark2}`,
  color: colors.grayDark2,
  fontSize: rem(20),
});

export const StyledCardButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
