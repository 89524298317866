import {
  InviteListingKeys,
  useArchiveInviteMutation,
} from '@gen2/api/invite-listing/hooks';
import { queryClient } from '@gen2/config';
import { useRouter, useToast } from '@gen2/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { StyledH3, StyledLink, StyledP, ToastContainer } from './modal.styled';

interface ArchivedModalProps {
  open: boolean;
  onClose: () => void;
  subject: string | undefined;
  id?: string;
}

export const ArchivedModal = ({
  open,
  onClose,
  subject,
  id: inviteId,
}: ArchivedModalProps) => {
  const router = useRouter();
  const { id } = router.query;
  const toast = useToast();
  const { mutate, isLoading } = useArchiveInviteMutation();

  const handleArchive = () => {
    if (id || inviteId) {
      mutate(id || inviteId, {
        onSuccess: () => {
          queryClient.invalidateQueries([InviteListingKeys.queryInviteList]);

          toast.show({
            text: (
              <ToastContainer>
                <span>
                  You’ve archived the Invite <strong>{`"${subject}"`}</strong>.
                </span>
                <StyledLink data-cy="login-link" to={`/archived-listing/${id}`}>
                  View Archived Invites
                </StyledLink>
              </ToastContainer>
            ),
            variant: 'info',
          });
          onClose();

          router.navigate(`/invite-listing`);
        },
        onError: (err) => {
          toast.show({
            text: 'Failed to archive invite',
            variant: 'error',
          });
          console.error(err);
        },
      });
    }
  };

  return (
    <Dialog
      id="archived-modal"
      open={open}
      onClose={onClose}
      aria-labelledby="archive-modal-title"
      aria-describedby="archive-modal-description"
      data-cy="archive-modal"
    >
      <ModalCloseButton
        data-cy="close-btn"
        aria-label="close"
        onClick={onClose}
      />
      <DialogContent dividers sx={{ maxWidth: '550px' }}>
        <StyledH3>
          Are you sure you want to archive Invite “{subject}”?
        </StyledH3>
        <StyledP>
          Archiving an invite means your contacts will no longer be able to
          access this Invite. You’ll still be able to view and download files
          from this Invite. This action cannot be undone.
          <br />
          <br />
          We will send a message to the contacts on this invite to inform them
          that the portal is now closed.
        </StyledP>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          variant="contained"
          data-cy="cancel-btn"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          data-cy="archive-btn"
          onClick={handleArchive}
          disabled={isLoading}
          loading={isLoading}
        >
          Yes, Archive Invite
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
