/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import { MuiAlert } from '@components/lib/alert/alert.styles';
import { MuiAutocomplete } from '@components/lib/autocomplete/autocomplete.styles';
import { MuiBadge } from '@components/lib/badge/badge.styles';
import { MuiButton } from '@components/lib/button/button.styles';
import { MuiIconButton } from '@components/lib/button/icon-button.styles';
import {
  MuiCheckbox,
  MuiFormControlLabel,
} from '@components/lib/checkbox/checkbox.styles';
import { MuiChip } from '@components/lib/chip/chip.styles';
import {
  MuiDateCalendar,
  MuiDayCalendar,
  MuiPickersArrowSwitcher,
  MuiPickersCalendarHeader,
  MuiPickersDay,
  MuiPickersPopper,
} from '@components/lib/date-picker/date-picker.styles';
import { MuiList } from '@components/lib/list/list.styles';
import { MuiLoadingButton } from '@components/lib/loading-button/loading-button.styles';
import {
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogContentText,
  MuiDialogTitle,
} from '@components/lib/modal/modal.styles';
import { MuiRadio } from '@components/lib/radio/radio.styles';
import {
  MuiSelect,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiMenuItem,
  MuiFormHelperText,
} from '@components/lib/select/select.styles';
import { MuiSlider } from '@components/lib/slider/slider.styles';
import { MuiTab, MuiTabs } from '@components/lib/tabs/tabs.styles';
import { MuiTextField } from '@components/lib/text-field/text-field.styles';
import { MuiTooltip } from '@components/lib/tooltip/tooltip.styles';
import { ThemeOptions } from '@mui/material';
import { palette } from './palette';
import { textSizes, typography } from './typography';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/lab/themeAugmentation';
import { colors } from '.';

export const baseThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1024,
      xl: 1200,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-size: ${textSizes.base}px;
          color: ${colors.grayDark3};
          overflow-x: hidden !important;
        }

        p {
          margin: unset;
        }

        input {
          font-family: inherit;
          color: inherit;
        }

        a {
          color: ${colors.secondaryDark1};
          text-decoration: underline;
        }

        a.noDecoration {
          text-decoration: none;
        }

        a:hover {
          color: ${colors.secondaryDark3};
          text-decoration: none;
        }

        /* Removed arrow from number input */
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;

        }

        input[type=number] {
          -moz-appearance: textfield;
        }
      `,
    },
    MuiButton,
    MuiTooltip,
    MuiTextField,
    MuiAutocomplete,
    MuiSelect,
    MuiInputLabel,
    MuiOutlinedInput,
    MuiMenuItem,
    MuiFormHelperText,
    MuiChip,
    MuiSlider,
    MuiAlert,
    MuiCheckbox,
    MuiFormControlLabel,
    MuiDialogTitle,
    MuiDialogContentText,
    MuiDialogContent,
    MuiDialogActions,
    MuiDialog,
    MuiDateCalendar,
    MuiPickersPopper,
    MuiPickersCalendarHeader,
    MuiPickersDay,
    MuiDayCalendar,
    MuiPickersArrowSwitcher,
    MuiLoadingButton,
    MuiBadge,
    MuiTab,
    MuiTabs,
    MuiList,
    MuiIconButton,
    MuiRadio,
  },
  typography,
  palette,
};
