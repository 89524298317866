import SearchBar from '@components/lib/searchbar/searchbar';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInviteListingQuery } from '@gen2/api/invite-listing/hooks';
import { useAuth } from '@gen2/hooks';
import { Invite } from '@gen2/types/invite';
import { Player } from '@lottiefiles/react-lottie-player';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TableHead as MuiTableHead,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Avatar, Indicator } from '@nx-fe/components';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce, useUnmount } from 'usehooks-ts';
import { dayjs } from '../../utils/time';
import { ArchivedModal } from '../invite-listing/invite-listing-item/modal';
import {
  StyledPaginationButton,
  StyledPaginationCount,
  StylePaginationPageSizeContainer,
} from '../team-member/layout/list-username.styled';
import { EmptyState } from './empty-state/empty-state';
import { InviteListingFilter } from './filter/filter';
import {
  Container,
  Filter,
  IndicatorContainer,
  LoadingWrapper,
  SearchBarContainer,
  SortBy,
  StyledTableCell,
  TableContainer,
  TableFilter,
  TableFooter,
  TableHead,
  TableHeadCell,
} from './invite-listing.styled';
import { useInviteListingStore } from './store';
import { Actions } from './table-cells/actions/actions';
import { ContactCell } from './table-cells/contacts/contacts';
import { Progress } from './table-cells/progress/progress';
import { Subject } from './table-cells/subject/subject';

const columnHelper = createColumnHelper<Invite>();
const fallbackData: Invite[] = [];
const sortOptions = [
  {
    key: 'created_desc',
    column: 'created_at',
    order: 'desc',
    label: 'Newest Invites First',
  },
  {
    key: 'created_asc',
    column: 'created_at',
    order: 'asc',
    label: 'Oldest Invites First',
  },
  {
    key: 'progress_desc',
    column: 'progression_percentage',
    order: 'desc',
    label: 'Most progress',
  },
  {
    key: 'progress_asc',
    column: 'progression_percentage',
    order: 'asc',
    label: 'Least progress',
  }
];

const InviteListing = () => {
  const { t } = useTranslation('inviteListing');
  const { featureFlags } = useAuth();
  const theme = useTheme();
  const aboveTablet = useMediaQuery(theme.breakpoints.up('md'));
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [sortColumn, setSortColumn] = useState<'created_at' | 'progression_percentage'>('created_at');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const debouncedSearch = useDebounce(search, 500);
  const { archive, setArchive, filter } = useInviteListingStore();

  useEffect(() => {
    if (filter) {
      setPage(1);
    }
  }, [filter]);

  const filters = useMemo(() => {
    const contactsIds = filter.selectedContacts.map((contact) => contact.id);
    const usersIds = filter.selectedUsers.map((user) => user.id);
    const assigneeUserIds = filter.selectedAssignees.map((user) => user.id);
    const assignedTeamIds = filter.selectedAssignedTeams.map((team) => team.id);
    const inviteIndicators = filter.selectedInviteIndicators;

    return {
      contacts: contactsIds.join(','),
      users: usersIds.join(','),
      assignees: assigneeUserIds.join(','),
      teams: assignedTeamIds.join(','),
      indicators: inviteIndicators.join(','),
      total: contactsIds.length + usersIds.length + assigneeUserIds.length + assignedTeamIds.length + inviteIndicators.length,
    };
  }, [filter.selectedContacts, filter.selectedUsers, filter.selectedAssignees, filter.selectedAssignedTeams, filter.selectedInviteIndicators]);

  const { data, isLoading } = useInviteListingQuery({
    per_page: limit,
    page: page,
    'filter[status]': [
      'sent',
      'progressing',
      'ready_for_review',
      'in_review',
      'completed',
    ],
    'filter[from_users]': filters.users,
    'filter[contacts]': filters.contacts,
    'filter[assignees]': filters.assignees,
    'filter[teams]': filters.teams,
    'filter[indicator]': filters.indicators,
    'sort[order]': sortOrder,
    'sort[column]': sortColumn,
    'filter[subject]': debouncedSearch,
  });

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('subject', {
        header: () => 'Invite Name',
        cell: (info) => (
          <Subject id={info.row.original.id} subject={info.getValue()} />
        ),
      }),
      columnHelper.accessor('indicator', {
        header: () => '',
        cell: ({ getValue }) => (
          <IndicatorContainer>
            <Indicator
              isShowAdornment={false}
              status={getValue() ?? ''}
              text={t(getValue() ?? '', { ns: 'indicators' })}
            />
          </IndicatorContainer>
        ),
      }),
      ...(aboveTablet
        ? [
          columnHelper.accessor('contacts', {
            header: () => 'Contacts',
            cell: (info) => (
              <ContactCell max={3} contacts={info.getValue()} />
            ),
          }),
        ]
        : []),
      columnHelper.accessor('progression_percentage', {
        header: () => 'Progress',
        cell: (info) => <Progress value={info.getValue() ?? 0} />,
      }),
      columnHelper.accessor('assignee_user', {
        header: () => 'Assignee',
        cell: ({ row }) =>
          row.original.assignee_user?.id ? (
            <Tooltip
              placement="top"
              title={
                row.original.assignee_user?.first_name +
                ' ' +
                row.original.assignee_user?.last_name
              }
            >
              <Avatar
                src={row.original.assignee_user?.avatar_url}
                alt={
                  row.original.assignee_user?.first_name +
                  ' ' +
                  row.original.assignee_user?.last_name
                }
              >
                {row.original.assignee_user?.first_name[0]}
                {row.original.assignee_user?.last_name[0]}
              </Avatar>
            </Tooltip>
          ) : (
            '-'
          ),
      }),
      columnHelper.accessor('due_at', {
        header: () => 'Due Date',
        cell: (info) =>
          info.getValue() ? dayjs(info.getValue()).format('DD-MMM-YYYY') : '-',
      }),
      ...(aboveTablet
        ? [
          columnHelper.accessor('last_activity_at', {
            header: () => 'Last Activity',
            cell: (info) =>
              info.getValue() ? dayjs(info.getValue()).fromNow() : '-',
          }),
        ]
        : []),
      columnHelper.display({
        id: 'action',
        header: '',
        cell: (info) => (
          <Actions
            id={info.row.original.id}
            subject={info.row.original.subject}
          />
        ),
      }),
    ];

    return cols;
  }, [aboveTablet]);

  const table = useReactTable({
    data: data?.data ?? fallbackData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useUnmount(() => {
    filter.clearFilter();
  });

  const handleNextPage = () => {
    if (data?.links.next) {
      setPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (data?.links.prev) {
      setPage((prev) => prev - 1);
    }
  };

  const handleLimitChange = useCallback((e: SelectChangeEvent<number>) => {
    setPage(1);
    setLimit(e.target.value as number);
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearch(e.target.value);
  };

  const resetSearch = () => {
    setPage(1);
    setSearch('');
  };

  const currentSortOption = useMemo(() => {
    return sortOptions.find((option) => option.column === sortColumn && option.order === sortOrder);
  }, [sortColumn, sortOrder]);

  const handleSortChange = useCallback((e: SelectChangeEvent<string>) => {
    const selectedSortOption = sortOptions.find((option) => option.key === e.target.value);

    if (!selectedSortOption) {
      return;
    }

    setSortOrder(selectedSortOption.order as 'asc' | 'desc');
    setSortColumn(selectedSortOption.column as 'created_at' | 'progression_percentage');
  }, []);

  return (
    <Container>
      <TableContainer>
        <TableHead>
          <TableFilter>
            <Filter>
              <Button
                variant={filters.total ? 'contained' : 'outlined'}
                color="secondary"
                onClick={filter.openFilter}
                data-cy="filter-button"
              >
                {filters.total ? `Filter (${filters.total})` : 'Filter'}
              </Button>
              {!!filters.total && (
                <Button
                  data-cy="clear-filter-button"
                  variant="outlined"
                  color="tertiary"
                  onClick={() => {
                    setPage(1);
                    filter.clearFilter();
                  }}
                >
                  Clear
                </Button>
              )}
            </Filter>
            <SortBy>
              <FormControl fullWidth size="small">
                <InputLabel id="sort-by">Sort By</InputLabel>
                <Select
                  labelId="sort-by"
                  id="sort-by-select"
                  inputProps={{
                    'data-cy': 'sort-by-select',
                  }}
                  label="Sort By"
                  fullWidth
                  value={currentSortOption?.key}
                  onChange={handleSortChange}
                >
                  {
                    sortOptions.map((option) => (
                      featureFlags?.manage_invites_part_2
                        ? (
                          <MenuItem key={option.key} value={option.key}>
                            {option.label}
                          </MenuItem>
                        )
                        : (
                          !['progress_desc', 'progress_asc'].includes(option.key) && (
                            <MenuItem key={option.key} value={option.key}>
                              {option.label}
                            </MenuItem>
                          )
                        )
                    ))
                  }
                </Select>
              </FormControl>
            </SortBy>
          </TableFilter>
          <SearchBarContainer>
            <SearchBar
              onChange={handleSearch}
              value={search}
              onReset={resetSearch}
              placeholder="Search by invite name"
              id="search-bar"
              autoFocus
              mode="dark"
              isVariant={false}
            />
          </SearchBarContainer>
        </TableHead>

        {isLoading ? (
          <LoadingWrapper>
            <Player
              autoplay
              loop
              src="/assets/initializing.json"
              style={{ height: '53px', width: '250px' }}
            ></Player>
          </LoadingWrapper>
        ) : data?.data.length ? (
          <>
            <div>
              <Table>
                <MuiTableHead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHeadCell key={header.id} $cell={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                        </TableHeadCell>
                      ))}
                    </TableRow>
                  ))}
                </MuiTableHead>
                <TableBody>
                  {table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <StyledTableCell
                          $cell={cell.column.columnDef.id ?? ''}
                          key={cell.id}
                          data-cy={`invite-listing-table-cell-${cell.column.columnDef.id}`}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <TableFooter>
              <StylePaginationPageSizeContainer data-cy="table-footer-pagination-page">
                Show
                <Select
                  labelId="page-size-label"
                  id="page-size-select"
                  value={limit}
                  onChange={handleLimitChange}
                  autoWidth
                  data-cy="footer-pagination-page-size"
                  color="info"
                >
                  {[10, 25, 50, 100].map((pageSize) => (
                    <MenuItem key={pageSize} value={pageSize}>
                      {pageSize}
                    </MenuItem>
                  ))}
                </Select>
                rows
              </StylePaginationPageSizeContainer>
              <StyledPaginationCount data-cy="table-footer-pagination-nav">
                <Button
                  variant="outlined"
                  color="ghost"
                  size="small"
                  data-cy="pagination-nav-first"
                  onClick={() => setPage(1)}
                >
                  First
                </Button>
                <StyledPaginationButton
                  onClick={handlePrevPage}
                  disabled={data?.links.prev === null}
                  size="small"
                  data-cy="pagination-nav-prev"
                >
                  <FontAwesomeIcon icon={solid('arrow-left')} />
                </StyledPaginationButton>
                <StyledPaginationButton
                  onClick={handleNextPage}
                  disabled={data?.links.next === null}
                  data-cy="pagination-nav-next"
                  size="small"
                >
                  <FontAwesomeIcon icon={solid('arrow-right')} />
                </StyledPaginationButton>
              </StyledPaginationCount>
            </TableFooter>
          </>
        ) : (
          <EmptyState />
        )}
      </TableContainer>
      <ArchivedModal
        id={archive.id}
        open={archive.isOpen}
        onClose={() => {
          setArchive({ isOpen: false, subject: '', id: '' });
        }}
        subject={archive.subject}
      />
      <InviteListingFilter />
    </Container>
  );
};

export default InviteListing;
