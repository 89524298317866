import { colors, weights } from '@components/theme/gen2';
import { Tabs } from '@gen2/app/components/base-popup-with-tabs/base-popup-with-tabs.styled';
import {
  Avatar,
  Box,
  ButtonBase,
  ButtonBaseProps,
  Chip,
  MenuItem,
  Stack,
  styled,
} from '@mui/material';

import { rem } from 'polished';

interface StyleBaseButtonProps extends ButtonBaseProps {
  num: number;
}

export const StyleBaseButton = styled(ButtonBase)<StyleBaseButtonProps>(
  ({ num }) => ({
    display: 'inline-flex',
    gap: rem(2),
    height: rem(38),
    width: num >= 100 ? rem(54) : rem(38),
    padding: rem(5),
    borderRadius: num >= 100 ? rem(20) : '50%',
    border: `1px solid ${colors.gray}`,
    backgroundColor: colors.white,
    color: colors.grayDark3,
  }),
);

export const StyledStack = styled(Stack)({
  width: '100%',
});

export const FilterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: rem(3),
});

export const SelectBox = styled(Box)({
  width: rem(208),
});

export const StyledChip = styled(Chip)({
  backgroundColor: colors.secondaryDark1,
  color: colors.white,
  cursor: 'pointer',
});

export const Info = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: colors.grayDark3,
  gap: rem(8),
});

export const Name = styled('div')({
  '> span': {
    display: 'block',
    fontSize: rem(12),
    color: colors.grayDark2,
  },
});

export const StyledAvatar = styled(Avatar)({
  width: rem(28),
  height: rem(28),
  background: colors.white,
  color: colors.grayDark2,
  fontSize: rem(14),
  fontWeight: weights.normal,
  textTransform: 'uppercase',
  '&.MuiAvatar-root': {
    border: `1px solid ${colors.grayDark2}`,
  },
});

export const ChipList = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: rem(5),
  marginTop: rem(12),
  maxWidth: rem(400),
  overflowX: 'auto',
});

export const ChipItem = styled(Chip)({
  '&.MuiChip-root:hover': {
    backgroundColor: colors.grayLight2,
  },
});

export const LoadingBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: rem(300),
  color: colors.grayDark3,
});

export const IndicatorChipItem = styled(ChipItem)({
  '& .MuiChip-label': {
    textTransform: 'unset'
  },
});

export const IndicatorMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: colors.grayLight3,
  },

  '& .status-label': {
    color: colors.grayDark2,
  },
});

export const StyledTabs = styled(Tabs)({
  flex: '0 0 auto',
});
