import { colors } from '@components/theme/gen2';
import { LinearProgress, linearProgressClasses, styled } from '@mui/material';

export const BorderLinearProgress = styled(LinearProgress)<{ value: number; }>(
  ({ theme, value }) => {

    let bgColor;

    switch (true) {
      case value === 100:
        bgColor = colors.blueDark1;
        break;
      case value < 20:
        bgColor = colors.blueLight4;
        break;
      case value < 40:
        bgColor = colors.blueLight3;
        break;
      case value < 60:
        bgColor = colors.blueLight2;
        break;
      case value < 80:
        bgColor = colors.blueLight1;
        break;
      case value < 100:
        bgColor = colors.blue;
        break;
    }

    return ({
      height: 10,
      borderRadius: 5,

      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: colors.grayLight2,
      },

      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        // backgroundColor: 'red',
        backgroundColor: `${bgColor} !important`,
      },
    });
  },
);

export const ProgressContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'end',
});
