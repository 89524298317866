import { useMsal } from '@azure/msal-react';
import { TIntegrationType } from '@gen2/api/integrations/api';
import { useDisconnectIntMutation } from '@gen2/api/integrations/hooks';
import { useAuth } from '@gen2/hooks';
import { Microsoft } from '@gen2/services/social-login/microsoft/microsoft';
import { PermissionsKeys } from '@gen2/types/permissions';
import { generateStateString } from '@gen2/utils/auth';
import { usePermissions } from '@gen2/utils/permissions/permissions';
import { useGoogleLogin } from '@react-oauth/google';
import { noop } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntegrationsStore } from './store';

export type TIntegrationAvailability = {
  google: boolean;
  microsoft: boolean;
  sharepoint: boolean;
};

export interface IIntegrationContext {
  isLoading: boolean;
  isConnected: (v: TIntegrationType) => boolean;
  disabled: TIntegrationAvailability;
  connect: (type: TIntegrationType) => void;
  disconnect: (type: TIntegrationType) => void;
  disconnectAsync: () => Promise<boolean>;
}

const IntegrationContext = createContext<IIntegrationContext>({
  isLoading: false,
  isConnected: () => false,
  disabled: {
    google: false,
    microsoft: false,
    sharepoint: false,
  },
  connect: noop,
  disconnect: noop,
  disconnectAsync: async () => false,
});

export type IntegrationProviderProps = {
  children: React.ReactNode;
};

export const IntegrationProvider = ({ children }: IntegrationProviderProps) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const { vendor } = useParams<{ vendor: TIntegrationType }>();
  const stateString = generateStateString();
  const redirectUri = useCallback((type: string) => {
    return `${window.location.origin}/auth/integration/${type}/callback`;
  }, []);
  const { setConnectConfirmOpen } = useIntegrationsStore();
  const { withPermissions } = usePermissions();

  const disabled = useMemo<TIntegrationAvailability>(() => {
    const val = {
      google: false,
      microsoft: false,
      sharepoint: false,
    };

    const isSharepointConnected =
      !!user?.organisations[0].accounts[0]?.integrations?.sharepoint
        ?.is_connected;

    // disable other integrations if one is connected
    if (user?.integrations?.drive?.is_connected || isSharepointConnected) {
      val.microsoft = true;
    }

    if (user?.integrations?.onedrive?.is_connected || isSharepointConnected) {
      val.google = true;
    }

    if (!withPermissions(PermissionsKeys.SHOW_INTEGRATION)) {
      val.sharepoint = true;
    }

    return val;
  }, [user, withPermissions]);

  const googleConnect = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: redirectUri('google'),
    select_account: true,
    state: stateString,
    enable_serial_consent: false,
    overrideScope: true,
    scope: 'https://www.googleapis.com/auth/drive.file',
  });

  const { instance: msInstance } = useMsal();
  const navigate = useNavigate();

  const microsoftConnect = () => {
    Microsoft.authOnedriveRedirect(msInstance, navigate);
  };

  const sharepointConnect = async () => {
    const isConfirmed = await setConnectConfirmOpen();

    setIsLoading(false);

    if (!isConfirmed) return;

    Microsoft.authSharepointRedirect(msInstance, navigate);
  };

  const {
    mutate: disconnectStorageMutation,
    mutateAsync: disconnectStorageMutationAsync,
  } = useDisconnectIntMutation();

  const { initialize } = useAuth();
  const { setAlert } = useIntegrationsStore();

  const isConnected = useCallback(
    (v: TIntegrationType) => {
      switch (v) {
        case 'google':
          return !!user?.integrations?.drive?.is_connected;
        case 'microsoft':
          return !!user?.integrations?.onedrive?.is_connected;
        case 'sharepoint':
          return !!user?.organisations[0].accounts[0]?.integrations?.sharepoint
            ?.is_connected;
        default:
          return false;
      }
    },
    [user],
  );

  const connect = (type: TIntegrationType) => {
    setIsLoading(true);

    switch (type) {
      case 'google':
        googleConnect();
        break;
      case 'microsoft':
        microsoftConnect();
        break;
      case 'sharepoint':
        sharepointConnect();
        break;
      default:
        break;
    }
  };

  const disconnect = (type: TIntegrationType) => {
    setIsLoading(true);

    const typeMessage = {
      google: 'Google Drive',
      microsoft: 'OneDrive',
      sharepoint: 'SharePoint',
    }[type];

    disconnectStorageMutation(
      { type },
      {
        onSuccess: () => {
          setAlert({
            message: `${typeMessage} was successfully disconnected!`,
            severity: 'warning',
            open: true,
          });
          initialize();
        },
        onError: () => {
          setAlert({
            message: `Failed to disconnect ${typeMessage}. Please try again.`,
            severity: 'error',
            open: true,
          });
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const disconnectAsync = async () => {
    try {
      await disconnectStorageMutationAsync({
        type: vendor || 'google',
      });

      setAlert({
        message: `Google drive was successfully disconnected!`,
        severity: 'warning',
        open: true,
      });
      initialize();
      return true;
    } catch (err) {
      setAlert({
        message: `Failed to disconnect Google Drive. Please try again.`,
        severity: 'error',
        open: true,
      });

      return false;
    }
  };

  return (
    <IntegrationContext.Provider
      value={{
        isLoading,
        isConnected,
        disabled,
        connect,
        disconnect,
        disconnectAsync,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};

export const useIntegrationContext = () => {
  return useContext(IntegrationContext);
};
