import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntFolder, TIntegrationType } from '@gen2/api/integrations/api';
import { useStorageFoldersQuery } from '@gen2/api/integrations/hooks';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledListItem } from '../integrations.styled';
import { useIntegrationsStore } from '../store';
import {
  StyledDialog,
  StyledFinderFooter,
  StyledFinderLabel,
  StyledNewFolderButton,
} from './finder.styled';

export type TIntFinderProps = {
  open: boolean;
  onClose: () => void;
  onSelect: (folder: IntFolder) => void;
};

export const IntFinder = ({ open, onClose, onSelect }: TIntFinderProps) => {
  const {
    currentFolder,
    setCurrentFolder,
    setCreateFolderOpen,
    setFinderOpen,
    site,
    setSitePickerOpen,
  } = useIntegrationsStore();
  const { t } = useTranslation('integrations');
  const { vendor } = useParams<{ vendor: TIntegrationType }>();

  const { data, isLoading, error } = useStorageFoldersQuery(
    vendor || 'google',
    {
      folder_id: currentFolder.id,
      site_id: site.id,
    },
    {
      enabled: open,
    },
  );

  const currentFolderLoc = useMemo(() => {
    if (site.name) return site.name + currentFolder.path;

    if (!currentFolder.path) return 'My Drive';

    return currentFolder.path;
  }, [site, currentFolder]);

  // @ts-ignore - error is type unknown
  if (error && error.status === 404) {
    setCurrentFolder({
      id: '',
      name: '',
      path: '',
    });
  }

  const onChangeFolder = (folder: IntFolder) => {
    setCurrentFolder(folder);
  };

  const onBack = () => {
    if (!data?.parent) return;

    setCurrentFolder(data.parent);
  };

  const handleOpenCreateFolder = () => {
    setCreateFolderOpen(true);
    setFinderOpen(false);
  };

  const handleChangeSite = async () => {
    setFinderOpen(false);

    await setSitePickerOpen();

    setFinderOpen(true);
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="integration-finder"
      aria-describedby="integration-finder"
      data-cy="integration-finder"
    >
      <ModalCloseButton
        data-cy="integration-finder-close"
        aria-label="close"
        onClick={onClose}
      >
        <FontAwesomeIcon icon={regular('close')} />
      </ModalCloseButton>
      <DialogTitle id="alert-dialog-title">
        {t(`finder.${vendor}.title`)}
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <StyledFinderLabel>
            {t(`finder.${vendor}.selected`)}: <span>{currentFolderLoc}</span>
          </StyledFinderLabel>
          <List dense>
            {data?.parent && (
              <ListItem disablePadding divider>
                <ListItemButton onClick={onBack}>
                  <ListItemText
                    primary={<FontAwesomeIcon icon={regular('ellipsis')} />}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {isLoading ? (
              <ListItem data-cy="loading-item" disablePadding>
                <ListItemButton>
                  <ListItemText primary="Loading folders..." />
                </ListItemButton>
              </ListItem>
            ) : data?.folders?.length ? (
              data.folders.map((folder, index) => (
                <StyledListItem
                  data-cy={`folder-${folder.id}`}
                  disablePadding
                  divider={index !== data.folders.length - 1}
                  key={folder.id}
                  onClick={() => onChangeFolder(folder)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={regular('folder-open')}
                        color="currentColor"
                      />
                    </ListItemIcon>
                    <ListItemText primary={folder.name} />
                  </ListItemButton>
                </StyledListItem>
              ))
            ) : (
              <ListItem data-cy="no-folders-found" disablePadding>
                <ListItemButton disabled>
                  <ListItemText primary="No folders found" />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          <StyledFinderFooter>
            <StyledNewFolderButton
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<FontAwesomeIcon icon={solid('plus')} />}
              onClick={handleOpenCreateFolder}
            >
              New Folder
            </StyledNewFolderButton>
            {vendor === 'sharepoint' && (
              <StyledNewFolderButton
                size="small"
                variant="outlined"
                color="secondary"
                onClick={handleChangeSite}
              >
                Change Site
              </StyledNewFolderButton>
            )}
          </StyledFinderFooter>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="integration-finder-cancel"
          onClick={onClose}
          color="tertiary"
          variant="outlined"
        >
          {t(`finder.${vendor}.cancel_btn`)}
        </Button>
        <Button
          data-cy="integration-finder-save"
          onClick={() => onSelect(currentFolder)}
          color="primary"
          variant="contained"
        >
          {t(`finder.${vendor}.confirm_btn`)}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
