import { Invite, Request } from '@gen2/types/invite';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { get, has } from 'lodash';
import {
  archiveInvite,
  getInvitesItem,
  getInvitesList,
  getInvitesRequests,
  InvitesListPayload,
  resendInvite,
  ResendPayload,
} from './api';

export enum InviteListingKeys {
  queryInviteList = 'getInviteList',
  queryInviteItem = 'getInviteItem',
  queryInvitesRequests = 'getInvitesRequests',
  queryDownloadFiles = 'getDownloadFiles',
}
export interface InvitesListResponse {
  data: { invites: Invite[] };
  links: {
    first: string | null;
    last: string | null;
    next: string | null;
    prev: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    path: string;
    per_page: number;
    to: number;
  };
}

export const useInviteListingQuery = (search: InvitesListPayload) =>
  useQuery({
    queryKey: [InviteListingKeys.queryInviteList, ...Object.values(search)],
    queryFn: async () => {
      const { data } = await getInvitesList(search);

      if (data && has(data, 'data')) {
        return {
          data: data.data.invites as Invite[],
          links: data.links,
          meta: data.meta,
        };
      }

      return null;
    },
  });

export const useInviteListQuery = (search: InvitesListPayload) =>
  useQuery({
    queryKey: [InviteListingKeys.queryInviteList, ...Object.values(search)],
    queryFn: async () => {
      const { data } = await getInvitesList({
        ...search,
        page: search.page,
        per_page: search.per_page,
      });

      // return true means there is no data in database, default is false
      let hasNoData = false;

      if (data && has(data, 'data')) {
        const invites = data.data.invites as Invite[];

        // check if filter[subject] is not empty
        const hasSubjectFilter =
          search['filter[subject]'] &&
          search['filter[subject]'].trim().length > 0;

        // if filter[subject] has value and invites is empty, set hasNoData to false - means no search results
        // if filter[subject] has no value and invites is empty, set hasNoData to true - means no data
        if (invites.length === 0) {
          hasNoData = !hasSubjectFilter;
        }

        return {
          data: invites,
          links: data.links,
          meta: data.meta,
          hasNoData, // flag is for invites to differentiate between no data and no search results
        };
      }

      return null;
    },
  });

export const useInviteListInfiniteQuery = (search: InvitesListPayload) =>
  useInfiniteQuery({
    queryKey: [InviteListingKeys.queryInviteList, search],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getInvitesList({
        ...search,
        page: pageParam,
        per_page: pageParam === 1 ? 25 : 10,
      });
      if (data && has(data, 'data')) {
        const hasMore = Boolean(get(data, 'links.next', null));
        return {
          data: data.data.invites as Invite[],
          next: hasMore,
          next_page: hasMore ? data.meta?.current_page + 1 : null,
        };
      }
      return null;
    },
    getNextPageParam: (lastPage) =>
      lastPage?.next ? lastPage?.next_page : null,
  });

export const useInviteItemQuery = (inviteId: string) =>
  useQuery({
    queryKey: [InviteListingKeys.queryInviteItem, inviteId],
    queryFn: async () => {
      const { data } = await getInvitesItem(inviteId);
      if (data && has(data, 'data') && has(data.data, 'invite')) {
        return data.data.invite as Invite;
      }
      return null;
    },
    enabled: !!inviteId,
    onError: (err) => {
      const error = err as AxiosResponse;

      if (error.status === 404) {
        window.location.href = "/404";
      }
    }
  });

export const useInvitesRequestsQuery = (inviteId: string) =>
  useQuery({
    queryKey: [InviteListingKeys.queryInvitesRequests, inviteId],
    queryFn: async () => {
      const { data } = await getInvitesRequests(inviteId);
      if (data && has(data, 'data') && has(data.data, 'requests')) {
        return data.data.requests as Request[];
      }
      return [];
    },
    enabled: !!inviteId,
  });

export const useArchiveInviteMutation = () =>
  useMutation({
    mutationFn: (id: string) => archiveInvite(id),
  });

export const useResendInviteMutation = () =>
  useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: ResendPayload }) =>
      resendInvite(id, payload),
  });
