import { Dialog, styled } from '@mui/material';
import { rem } from 'polished';

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: rem(550),
    margin: 0,
  },
});
