import { useSafeQuery } from '@gen2/hooks';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  connectInt,
  createIntFolder,
  disconnectInt,
  getStorageFolders,
  GetIntFoldersParams,
  GetIntFoldersResponse,
  getStorageSettings,
  saveIntFolder,
  TIntegrationType,
  getSharepointSites,
  SharepointSitesResponse,
  GetIntSettingsResponse,
} from './api';

export enum IntegrationKeys {
  connectInt = 'connectInt',
  disconnectInt = 'disconnectInt',
  getStorageFolders = 'getStorageFolders',
  saveIntFolder = 'saveIntFolder',
  storageSettings = 'storageSettings',
  createStorageFolder = 'createStorageFolder',
  getSharepointSites = 'getSharepointSites',
}

export const useStorageFoldersQuery = (
  vendor: TIntegrationType,
  params?: GetIntFoldersParams,
  options?: UseQueryOptions<GetIntFoldersResponse['data']>,
) => {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      IntegrationKeys.getStorageFolders,
      vendor,
      params?.folder_id,
      params?.site_id,
    ],
    queryFn: async (): Promise<GetIntFoldersResponse['data']> => {
      const response = await getStorageFolders(vendor, params);
      return response.data?.data;
    },
    ...options,
  });
};

export const useConnectIntMutation = () =>
  useMutation({
    mutationKey: [IntegrationKeys.connectInt],
    mutationFn: connectInt,
  });

export const useDisconnectIntMutation = () =>
  useMutation({
    mutationKey: [IntegrationKeys.disconnectInt],
    mutationFn: disconnectInt,
  });

export const useStorageFolderMutation = () =>
  useMutation({
    mutationKey: [IntegrationKeys.saveIntFolder],
    mutationFn: saveIntFolder,
  });

export const useStorageSettings = (
  vendor: TIntegrationType,
  options?: UseQueryOptions<GetIntSettingsResponse>,
) => {
  return useQuery<GetIntSettingsResponse>({
    queryKey: [IntegrationKeys.storageSettings, vendor],
    queryFn: async () => {
      const response = await getStorageSettings(vendor);
      return response.data as GetIntSettingsResponse;
    },
    ...options,
  });
};

export const useCreateStorageFolderMutation = () =>
  useMutation({
    mutationKey: [IntegrationKeys.createStorageFolder],
    mutationFn: createIntFolder,
  });

export const useSharepointSitesQuery = (
  options?: UseQueryOptions<SharepointSitesResponse>,
) => {
  return useSafeQuery<SharepointSitesResponse>(
    [IntegrationKeys.getSharepointSites],
    async () => {
      const res = await getSharepointSites();
      return res.data;
    },
    options,
  );
};
