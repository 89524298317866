import { colors } from '@components/theme/gen2';
import { Box, styled, TableCell } from '@mui/material';
import { rem } from 'polished';

export const Container = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: colors.grayLight2,

  [theme.breakpoints.up('md')]: {
    padding: rem(20),
  },
}));

export const TableContainer = styled('div')(({ theme }) => ({
  backgroundColor: colors.white,
  maxWidth: rem(1350),
  marginLeft: 'auto',
  marginRight: 'auto',
  height: '100%',

  [theme.breakpoints.up('md')]: {
    borderRadius: rem(10),
  },
}));

export const TableHead = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  padding: rem(20),
  gap: rem(20),
  borderBottom: `1px solid ${colors.grayLight1}`,

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: rem(32),
    paddingRight: rem(32),
  },
}));

export const TableHeadCell = styled(TableCell)<{ $cell: string }>(
  ({ $cell, theme }) => ({
    padding: rem(10),
    textTransform: 'uppercase',
    color: colors.grayDark2,
    fontSize: rem(12),

    ...($cell === 'subject' && {
      width: rem(150),
    }),

    ...($cell === 'action' && {
      width: rem(50),
      backgroundColor: colors.grayLight3,
    }),
  }),
);

export const TableFilter = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(20),
});

export const Filter = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(5),

  button: {
    whiteSpace: 'nowrap',
  },
});

export const SortBy = styled('div')({
  minWidth: rem(250),
});

export const SearchBarContainer = styled('div')({
  width: rem(395),
});

export const TableFooter = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: rem(15),
});

export const IndicatorContainer = styled('div')({
  width: 'fit-content',

  '& div': { minWidth: 'unset' },
});

export const StyledTableCell = styled(TableCell)<{ $cell: string }>(
  ({ $cell }) => {
    return {
      paddingTop: rem(5),
      paddingBottom: rem(5),
      paddingLeft: rem(10),
      paddingRight: rem(10),

      ...($cell === 'subject' && {
        width: rem(150),
      }),

      ...($cell === 'action' && {
        backgroundColor: colors.grayLight3,
      }),
    };
  },
);

export const LoadingWrapper = styled(Box)({
  minHeight: rem(574),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
