/* eslint-disable @typescript-eslint/no-explicit-any */
import { LiveFormType } from '@gen2/app/invites/send-invites/store';
import { Box, Stack, TextField, MenuItem, Alert } from '@mui/material';
import { StyledSwitch } from '@nx-fe/components';
import React from 'react';
import { AnnotationTypeEnum, TAssignee } from '../utils/types';
import { DraggableAnnotation } from './draggableAnnotations/draggableAnnotations';
import {
  StyledFormTitle,
  StyledLabel,
  StyledTypography,
} from './sidePanel.styled';

interface SidePanelProps {
  users: TAssignee[];
  handleChange?: (e: any) => void;
  currSignee: TAssignee;
  onDragStart: (event: React.DragEvent<HTMLDivElement>, type: string) => void;
  onDragEnd: (e: any) => void;
  isTemplate?: boolean;
  type: LiveFormType;
  checked?: boolean;
  handleCheck?: (e: any) => void;
}

const SidePanel = ({
  users,
  handleChange,
  currSignee,
  onDragStart,
  onDragEnd,
  isTemplate = false,
  type,
  checked = false,
  handleCheck,
}: SidePanelProps) => {
  return (
    <Box paddingX={2} paddingTop={3}>
      <Stack spacing={5}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <StyledSwitch
            color="primary"
            checked={checked}
            onChange={handleCheck}
            data-cy="switch"
          />
          <StyledTypography>
            {' '}
            {checked
              ? 'Toggle off to resume form designing'
              : 'Toggle on to fill out and sign the form'}{' '}
          </StyledTypography>
        </Box>
        <Box>
          <StyledFormTitle
            style={{ paddingBottom: '20px', paddingTop: '10px' }}
          >
            Add Fields<span> | Drag & drop</span>
          </StyledFormTitle>
          <DraggableAnnotation
            type={AnnotationTypeEnum.TextField}
            label="Text Field"
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            isDraggable={!checked}
          />
          <DraggableAnnotation
            type={AnnotationTypeEnum.CheckBox}
            label="Check Box"
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            isDraggable={!checked}
          />
          <DraggableAnnotation
            type={AnnotationTypeEnum.RadioButton}
            label="Radio Button"
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            isDraggable={!checked}
          />
        </Box>
        <Box>
          <StyledFormTitle
            style={{ paddingBottom: '20px', paddingTop: '10px' }}
          >
            Signatures<span> | Drag & drop</span>
          </StyledFormTitle>
          {isTemplate && type === 'multi' && (
            <Alert
              icon={false}
              severity="info"
              sx={{ maxWidth: '279px', marginBottom: '20px' }}
            >
              You can only place signatures for a shared form when creating an
              invite.
            </Alert>
          )}
          {type === 'multi' && handleChange && (
            <Box paddingBottom={2}>
              <StyledLabel>Placing fields for:</StyledLabel>
              <TextField
                id="assignee-select"
                inputProps={{
                  'data-cy': 'assignee-select',
                }}
                select
                value={currSignee.email}
                fullWidth
                onChange={handleChange}
              >
                {users.map((option: TAssignee) => (
                  <MenuItem key={option.id} value={option.email}>
                    {`${option.first_name} ${option.last_name}`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
          <DraggableAnnotation
            type={AnnotationTypeEnum.SIGNATURE}
            label="Signature"
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            isDraggable={!((isTemplate && type === 'multi') || checked)}
          />
          <DraggableAnnotation
            type={AnnotationTypeEnum.INITIAL}
            label="Initial"
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            isDraggable={!((isTemplate && type === 'multi') || checked)}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default SidePanel;
