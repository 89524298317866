import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SharepointSite } from '@gen2/api/integrations/api';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSharepointSitesQuery } from '../../../../api/integrations/hooks';
import { useIntegrationsStore } from '../../store';
import { StyledDialog } from './sharepoint-sites-modal.styled';

const SharepointSitesModal = () => {
  const { t } = useTranslation('integrations');
  const { site, cancelSite, confirmSite, changeSite } = useIntegrationsStore();
  const { data } = useSharepointSitesQuery({
    enabled: site.isOpen,
  });
  const [currentSite, setCurrentSite] = useState<SharepointSite>({
    id: '',
    name: '',
    path: '',
  });

  useEffect(() => {
    if (!site.id) return;

    setCurrentSite({
      id: site.id,
      name: site.name,
      path: site.path || '',
    });
  }, [site, setCurrentSite]);

  // pre-select if there is only one site
  useEffect(() => {
    if (!data?.data?.sites?.length) return;

    if (data.data.sites.length === 1) {
      setCurrentSite(
        data.data.sites ? data.data.sites[0] : { id: '', name: '', path: '' },
      );
    }
  }, [data]);

  const handleSiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pickedSite = data?.data?.sites.find(
      (s) => s.id === event.target.value,
    );

    if (!pickedSite) return;
    setCurrentSite(pickedSite);
  };

  const onContinue = () => {
    if (!currentSite.id) return;

    confirmSite({
      id: currentSite.id,
      name: currentSite.name,
      path: currentSite.path,
      isOpen: false,
    });
  };

  return (
    <StyledDialog
      open={site.isOpen}
      onClose={cancelSite}
      aria-labelledby="sites-modal"
      aria-describedby="sites-modal"
      data-cy="sites-modal"
    >
      <ModalCloseButton
        data-cy="sites-modal-close"
        aria-label="close"
        onClick={cancelSite}
      >
        <FontAwesomeIcon icon={regular('close')} />
      </ModalCloseButton>
      <DialogTitle id="alert-dialog-title">
        {t('sharepoint.sites.title')}
      </DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          aria-labelledby="sites-label"
          name="site"
          onChange={handleSiteChange}
          value={currentSite.id}
          defaultValue={site.id}
        >
          {!!data?.data?.sites?.length &&
            data.data.sites.map((s) => (
              <FormControlLabel
                key={s.id}
                value={s.id}
                control={<Radio color="secondary" />}
                label={s.name}
              />
            ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="integration-confirm-cancel"
          onClick={cancelSite}
          color="tertiary"
          variant="outlined"
        >
          {t('sharepoint.sites.cancel_btn')}
        </Button>
        <Button
          data-cy="integration-confirm-save"
          onClick={onContinue}
          color="primary"
          variant="contained"
        >
          {t('sharepoint.sites.confirm_btn')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default SharepointSitesModal;
