import InviteListing from '@gen2/app/invite-listing-v2/invite-listing';
import { useAuth } from '@gen2/hooks';
import { useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useInviteListing } from '../hook/useInviteListing';
import { CustomeToggleBtn } from '../icon-component/icon-component';
import { Center, DefaultView } from './invite-listing-layout.styled';

const InviteListingMain: FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('inviteListing');
  const { openLeft, toggleLeftSidebar } = useInviteListing();
  const location = useLocation();

  return !matches ? (
    <Center data-cy="main">
      <CustomeToggleBtn
        data-cy="toggle-btn"
        direction="left"
        iconDirection={openLeft ? 'left' : 'right'}
        onClick={toggleLeftSidebar}
      />
      <DefaultView data-cy="default-view">
        <img src="/assets/invites-image.svg" alt="invites" />
        <p>
          <Trans
            t={t}
            i18nKey={location.pathname.includes('draft') ? 'draftText' : 'text'}
            ns="inviteListing"
          />
        </p>
      </DefaultView>
    </Center>
  ) : null;
};

export const InviteListingWrapper = () => {
  const { featureFlags } = useAuth();

  if (featureFlags.invite_listing_update) {
    return <InviteListing />;
  }

  return <InviteListingMain />;
};

export default InviteListingMain;
