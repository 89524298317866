import { TContact } from '@gen2/types/contact';
import { Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { ContactAvatar, ContactsAvatarGroup } from './contacts.styled';

export const ContactCell = ({
  max,
  contacts,
}: {
  max: number;
  contacts: TContact[];
}) => {
  const surplusContactNames = useMemo(() => {
    if (contacts.length > max) {
      const adjustedMax = max - 1;

      return contacts
        .slice(adjustedMax)
        .map((contact) => contact.first_name + ' ' + contact.last_name)
        .join(', ');
    }

    return [];
  }, [contacts, max]);

  return (
    <ContactsAvatarGroup
      spacing="medium"
      max={max}
      total={contacts.length}
      slotProps={{
        additionalAvatar: {
          className: 'surplus',
        },
      }}
      renderSurplus={(surplus) => (
        <Tooltip title={surplusContactNames} placement="top">
          <span>+{surplus.toString()[0]}</span>
        </Tooltip>
      )}
    >
      {contacts &&
        contacts.map((contact) => (
          <Tooltip
            key={contact.id}
            title={contact.first_name + ' ' + contact.last_name}
            placement="top"
          >
            <ContactAvatar
              key={contact.id}
              alt={contact.first_name + ' ' + contact.last_name}
              src={contact.avatar_url}
            >
              {contact.first_name[0] + contact.last_name[0]}
            </ContactAvatar>
          </Tooltip>
        ))}
    </ContactsAvatarGroup>
  );
};
