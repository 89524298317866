import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '@gen2/hooks';
import { useSidebarStore } from '@gen2/hooks/stores';
import { Permissions } from '@gen2/utils/permissions/permissions';
import { useCallback } from 'react';
import { invites, MenuItem } from '../data';
import {
  StyledSidebarList,
  StyledSidebarListItem,
  StyledSidebarListItemLink,
  StyledSubMenuHeading,
} from '../sidebar.styled';

export const Invites = () => {
  const store = useSidebarStore();
  const { featureFlags } = useAuth();

  const onItemClick = (item: MenuItem) => {
    store.setOpen(false);

    if (item.id === 'home') return; // dashboard has no submenu

    store.setSelected(item);
    store.setSubMenuOpen(false);
  };

  const getLink = useCallback((item: MenuItem) => {
    if (item.id === 'manageInvites' && featureFlags?.invite_listing_update) {
      return '/invite-listing-v2';
    }

    return item.to || '';
  }, []);

  return (
    <StyledSidebarList data-cy="sidebar-invites">
      <StyledSubMenuHeading>Invites</StyledSubMenuHeading>
      {invites.map((item) => (
        <Permissions key={item.title} allow={item.permission ?? []}>
          <StyledSidebarListItem>
            <StyledSidebarListItemLink
              onClick={() => onItemClick(item)}
              to={item.to ?? ''}
              data-cy={`sidebar-invites-link-${item?.title?.toLowerCase()}`}
            >
              <FontAwesomeIcon size="lg" icon={item.icon || 'x'} />
              <span>{item.title}</span>
            </StyledSidebarListItemLink>
          </StyledSidebarListItem>
        </Permissions>
      ))}
    </StyledSidebarList>
  );
};
