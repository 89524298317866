import { IntFolder } from '@gen2/api/integrations/api';
import { AlertColor } from '@mui/material';
import { noop } from 'lodash';
import { create } from 'zustand';

type AlertSettings = {
  severity: AlertColor;
  message: string;
  open: boolean;
};

export type Site = {
  id: string;
  name: string;
  path: string;
  isOpen: boolean;
};

export interface IIntegrationsStore {
  alert: AlertSettings;
  selectedFolder: IntFolder | null;
  currentFolder: IntFolder;
  isFinderOpen: boolean;
  isCreateFolderOpen: boolean;
  isConnectConfirmOpen: boolean;

  setAlert: (alert: AlertSettings) => void;
  setSelectedFolder: (folder: IntFolder | null) => void;
  setCurrentFolder: (folder: IntFolder) => void;
  setFinderOpen: (isOpen: boolean) => void;
  setCreateFolderOpen: (isOpen: boolean) => void;

  confirmConnect: () => void;
  cancelConnect: () => void;

  setConnectConfirmOpen: () => Promise<boolean>;

  site: Site;

  setSitePickerOpen: () => Promise<Site | null>;

  confirmSite: (site: Site) => void;
  cancelSite: () => void;
  changeSite: (site: Site) => void;
}

export const useIntegrationsStore = create<IIntegrationsStore>()((set) => ({
  alert: {
    severity: 'success',
    message: '',
    open: false,
  },
  selectedFolder: null,
  currentFolder: {
    id: '',
    name: '',
    path: '',
  },
  isFinderOpen: false,
  isCreateFolderOpen: false,
  isConnectConfirmOpen: false,

  site: {
    id: '',
    name: '',
    path: '',
    isOpen: false,
  },

  setAlert: (alert) => set({ alert }),
  setSelectedFolder: (selectedFolder) => set({ selectedFolder }),
  setCurrentFolder: (currentFolder) => set({ currentFolder }),
  setFinderOpen: (isOpen) => set({ isFinderOpen: isOpen }),
  setCreateFolderOpen: (isOpen) => set({ isCreateFolderOpen: isOpen }),

  // async modals -- connect confirm
  confirmConnect: noop,
  cancelConnect: noop,

  setConnectConfirmOpen: async () =>
    new Promise((resolve) => {
      set({
        isConnectConfirmOpen: true,
        confirmConnect: () => {
          resolve(true);
          set({ isConnectConfirmOpen: false });
        },
        cancelConnect: () => {
          resolve(false);
          set({ isConnectConfirmOpen: false });
        },
      });
    }),

  // async modals -- site picker
  confirmSite: noop,
  cancelSite: noop,

  changeSite: (site) => set({ site }),
  setSitePickerOpen: async () =>
    new Promise((resolve) => {
      set((state) => ({
        site: {
          ...state.site,
          isOpen: true,
        },
        confirmSite: (site: Site) => {
          resolve(site);
          set({ site });
        },
        cancelSite: () => {
          resolve(null);
          set((s) => ({
            site: {
              ...s.site,
              isOpen: false,
            },
          }));
        },
      }));
    }),
}));
