import { colors } from '@components/theme/gen2';
import { AvatarGroup, styled } from '@mui/material';
import { Avatar } from '@nx-fe/components';
import { rem } from 'polished';

export const ContactsAvatarGroup = styled(AvatarGroup)({
  alignItems: 'center',
  justifyContent: 'flex-end',

  '& .surplus': {
    backgroundColor: colors.grayLight2,
    color: colors.grayDark2,
    border: `1px solid ${colors.grayDark2} !important`,
    width: rem(34),
    height: rem(34),
    fontSize: rem(16),
  },
});

export const ContactAvatar = styled(Avatar)({
  border: `1px solid ${colors.grayDark2} !important`,
  backgroundColor: colors.white,
});
