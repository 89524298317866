import { colors, textSizes } from '@components/theme/gen2';
import { Avatar, AvatarProps, IconButton, styled } from '@mui/material';
import { rem } from 'polished';
import { Link } from 'react-router-dom';

interface StyledContactAvatarProps extends AvatarProps {
  size?: number;
}

export const StyledContactAvatar = styled(Avatar)<StyledContactAvatarProps>(
  ({ size }) => ({
    width: size ? rem(size) : rem(28),
    height: size ? rem(size) : rem(28),
    fontSize: size ? rem(textSizes.xl) : rem(textSizes.sm),
    backgroundColor: 'transparent',
    color: colors.grayDark2,
    border: `${rem(1)} solid ${colors.grayDark2}`,
  }),
);

interface StyledUsernameTextProps {
  noUnderline?: boolean;
}

export const StyledUsername = styled('div')<StyledUsernameTextProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: rem(10),
}));

export const StyledUsernameText = styled('div')<StyledUsernameTextProps>(
  ({ noUnderline = false }) => ({
    color: noUnderline ? colors.grayDark3 : 'inherit',
  }),
);

export const StyledPaginationContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

export const StyledPaginationSpan = styled('span')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: rem(1),
});

export const StyledPaginationCount = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: rem(5),
});

export const StyledPaginationButton = styled(IconButton)({
  color: colors.grayDark2,
});

export const StyledPaginationSelect = styled('select')({
  padding: rem(5),
  border: '1px solid rgba(113, 115, 122, 1)',
  borderRadius: '3px',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  color: 'rgba(113, 115, 122, 1)',
  margin: '0 2px',
});

export const StylePaginationPageSizeContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: rem(5),
});

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: colors.blueDark1,
});

export const RouteLink = styled(Link)({
  color: colors.blueDark1,
});
