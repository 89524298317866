import {
  Alert,
  AlertColor,
  SnackbarCloseReason,
  SnackbarProps,
} from '@mui/material';
import { StyledSnackbar } from './toast.styles';

export type ToastProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => void;
  severity?: AlertColor;
  variant?: 'filled';
  icon?: boolean;
  duration?: number;
} & Omit<SnackbarProps, 'children'>;

export const Toast = ({
  open,
  onClose,
  children,
  severity = 'info',
  variant = 'filled',
  icon = false,
  duration = 6000,
  ...props
}: ToastProps) => {
  return (
    <StyledSnackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={duration}
      onClose={onClose}
      {...props}
    >
      <Alert
        variant={variant}
        severity={severity}
        icon={icon}
        onClose={onClose}
        sx={{ whiteSpace: 'pre-line' }}
      >
        {children}
      </Alert>
    </StyledSnackbar>
  );
};

export default Toast;
