import { colors, weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledEmptyState = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  paddingTop: rem(123),
  paddingBottom: rem(208),
  color: colors.grayDark1,
  textAlign: 'center',

  'p:first-child': {
    fontSize: rem(15),
    fontWeight: weights.medium,
    margin: 0,
  },

  'p:last-child': {
    width: rem(200),
    margin: 0,
  },
});
