/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unused-imports/no-unused-vars */
import { AnnotationTypeEnum } from './types';

const handleDrop = async (
  e: any,
  inst: any,
  PSPDFKit: any,
  currSigneeRef: any,
  currUserRef: any,
  onPageIndexRef: any,
) => {
  e.preventDefault();
  e.stopPropagation();
  const dataArray = e.dataTransfer.getData('text').split('%');

  const [name, email, InstantId, annotationType, liveFormType] = dataArray;
  const instantId = PSPDFKit.generateInstantId();
  const signee = currSigneeRef.current;
  const user = currUserRef.current;
  const pageIndex = onPageIndexRef.current;
  let rectWidth = 120;
  let rectHeight = 40;
  switch (annotationType) {
    case AnnotationTypeEnum.INITIAL:
      rectWidth = 80;
      rectHeight = 52;
      break;

    case AnnotationTypeEnum.SIGNATURE:
      rectWidth = 120;
      rectHeight = 60;
      break;

    case AnnotationTypeEnum.DS:
      rectWidth = 250;
      rectHeight = 100;
      break;

    case AnnotationTypeEnum.RadioButton:
      rectWidth = 24;
      rectHeight = 24;
      break;

    case AnnotationTypeEnum.CheckBox:
      rectWidth = 24;
      rectHeight = 24;
      break;

    case AnnotationTypeEnum.TextField:
      rectWidth = 120;
      rectHeight = 40;
      break;

    default:
      break;
  }
  const clientRect = new PSPDFKit.Geometry.Rect({
    left: e.clientX - rectWidth / 2,
    top: e.clientY - rectHeight / 2,
    height: rectHeight,
    width: rectWidth,
  });
  const pageRect = inst.transformContentClientToPageSpace(
    clientRect,
    pageIndex,
  ) as any;
  if (
    annotationType === AnnotationTypeEnum.SIGNATURE ||
    annotationType === AnnotationTypeEnum.INITIAL
  ) {
    let widget;
    const customData = {
      createdBy: user.id,
      type: annotationType,
      isInitial: annotationType === AnnotationTypeEnum.INITIAL,
    };
    widget = new PSPDFKit.Annotations.WidgetAnnotation({
      boundingBox: pageRect,
      formFieldName: instantId,
      id: instantId,
      pageIndex,
      name: instantId,
      subject: liveFormType,
      customData: customData,
    });
    if (liveFormType === 'multi') {
      widget = widget.set('customData', {
        ...widget.customData,
        signerID: signee.id,
        name: signee.first_name + ' ' + signee.last_name,
      });
    }
    const formField = new PSPDFKit.FormFields.SignatureFormField({
      annotationIds: PSPDFKit.Immutable.List([widget.id]),
      name: instantId,
      id: instantId,
      readOnly: signee.id !== user.id,
    });
    await inst.create([widget, formField]);
  } else if (annotationType === AnnotationTypeEnum.RadioButton) {
    const radioWidget1 = new PSPDFKit.Annotations.WidgetAnnotation({
      id: instantId,
      pageIndex: pageIndex,
      formFieldName: instantId,
      boundingBox: pageRect,
      customData: {
        createdBy: user.id,
        type: annotationType,
      },
    });
    const formField = new PSPDFKit.FormFields.RadioButtonFormField({
      id: instantId,
      name: instantId,
      annotationIds: new PSPDFKit.Immutable.List([radioWidget1.id]),
      options: new PSPDFKit.Immutable.List([
        new PSPDFKit.FormOption({
          label: 'Option 1',
          value: '1',
        }),
      ]),
      defaultValue: '1',
    });
    await inst.create([radioWidget1, formField]);
  } else if (annotationType === AnnotationTypeEnum.CheckBox) {
    const checkBoxWidget = new PSPDFKit.Annotations.WidgetAnnotation({
      id: instantId,
      name: instantId,
      pageIndex: pageIndex,
      formFieldName: instantId,
      boundingBox: pageRect,
      customData: {
        createdBy: user.id,
        type: annotationType,
      },
    });
    const formField = new PSPDFKit.FormFields.CheckBoxFormField({
      id: instantId,
      name: instantId,
      annotationIds: new PSPDFKit.Immutable.List([checkBoxWidget.id]),
      defaultValue: false,
      options: new PSPDFKit.Immutable.List([
        new PSPDFKit.FormOption({
          label: 'Option 1',
          value: '1',
        }),
      ]),
    });
    await inst.create([checkBoxWidget, formField]);
  } else if (annotationType === AnnotationTypeEnum.TextField) {
    const textBoxWidget = new PSPDFKit.Annotations.WidgetAnnotation({
      id: instantId,
      pageIndex: pageIndex,
      name: instantId,
      formFieldName: instantId,
      boundingBox: pageRect,
      customData: {
        createdBy: user.id,
        type: annotationType,
      },
    });
    const textField = new PSPDFKit.FormFields.TextFormField({
      annotationIds: new PSPDFKit.Immutable.List([textBoxWidget.id]),
      id: instantId,
      name: instantId,
      label: 'Text Field',
      maxLength: 100,
      multiLine: false,
    });
    await inst.create([textBoxWidget, textField]);
  } else {
    const text = new PSPDFKit.Annotations.TextAnnotation({
      pageIndex,
      boundingBox: pageRect,
      text: {
        format: 'plain',
        value: annotationType === 'name' ? name : new Date().toDateString(),
      },
      name: name,
      customData: {
        createdBy: user.id,
        type: annotationType,
      },
      font: 'Helvetica',
      fontSize: rectHeight * 0.6,
      horizontalAlign: 'left',
      verticalAlign: 'top',
      isEditable: false,
    });
    await inst.create(text);
  }
};

export default handleDrop;
