import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { StyledIconButton } from '../invite-listing-item/invite-listing-item.styled';
import {
  IconContainer,
  StyledDivider,
  StyledDividerStyleProps,
} from '../layout/invite-listing-layout.styled';

interface CloseBtnProps {
  onClick: () => void;
}

export const CloseBtn = ({ onClick }: CloseBtnProps) => {
  return (
    <StyledIconButton aria-label="close" onClick={onClick}>
      <FontAwesomeIcon size="xs" icon={regular('xmark')} />
    </StyledIconButton>
  );
};

export const ArrowLeft = () => {
  const getLink = useCallback(() => {
    return '/invite-listing';
  }, []);

  return (
    <Link to={getLink()}>
      <span>
        <FontAwesomeIcon icon={regular('arrow-left')} />
      </span>
    </Link>
  );
};

interface CustomeToggleBtnProps extends StyledDividerStyleProps {
  onClick?: () => void;
  iconDirection?: 'left' | 'right';
}

export const CustomeToggleBtn = ({
  onClick,
  direction = 'left',
  iconDirection,
}: CustomeToggleBtnProps) => {
  return (
    <StyledDivider data-cy="toggle-btn" direction={direction} onClick={onClick}>
      <IconContainer>
        <FontAwesomeIcon
          icon={
            iconDirection
              ? iconDirection === 'left'
                ? light('chevrons-left')
                : light('chevrons-right')
              : direction === 'left'
              ? light('chevrons-left')
              : light('chevrons-right')
          }
          size="lg"
        />
      </IconContainer>
    </StyledDivider>
  );
};
