import { StyledEmptyState } from './empty-state.styled';

export const EmptyState = () => {
  return (
    <StyledEmptyState data-cy="empty-state">
      <img src="/assets/invites-image.svg" alt="invites" />
      <p>No invites here...</p>
      <p>Invites will show here once they have been sent.</p>
    </StyledEmptyState>
  );
};
