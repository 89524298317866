import { colors, weights } from '@components/theme/gen2';
import { styled } from '@mui/material/styles';
import { rem } from 'polished';

interface StatusColors {
  background: string;
  color: string;
  border: string;
}

export const statusColors: { [key: string]: StatusColors; } = {
  complete: {
    background: colors.greenLight4,
    color: colors.greenDark2,
    border: colors.greenDark2,
  },
  accepted: {
    background: colors.greenLight4,
    color: colors.greenDark2,
    border: colors.greenDark2,
  },
  progressing: {
    background: colors.white,
    color: colors.greenDark2,
    border: colors.greenDark2,
  },
  sent: {
    background: colors.white,
    color: colors.blueDark2,
    border: colors.blueDark2,
  },
  noProgress: {
    background: colors.secondaryLight4,
    color: colors.secondaryDark1,
    border: colors.secondaryDark1,
  },
  inReview: {
    background: colors.white,
    color: colors.orangeDark2,
    border: colors.orangeDark2,
  },
  awaitingReview: {
    background: colors.orangeLight4,
    color: colors.orangeDark2,
    border: colors.orangeDark2,
  },
  overdue: {
    background: colors.redLight4,
    color: colors.redDark2,
    border: colors.redDark2,
  },
  dueToday: {
    background: colors.redLight4,
    color: colors.redDark1,
    border: colors.redDark1,
  },
  revision: {
    background: colors.redLight4,
    color: colors.redDark1,
    border: colors.redDark1,
  },
  draft: {
    background: colors.grayLight3,
    color: colors.grayDark1,
    border: colors.grayDark1,
  },
};

export interface LabelProps {
  status: keyof typeof statusColors;
  isShowAdornment?: boolean;
  borderRounded?: boolean;
  single?: boolean;
  size?: 'small' | 'medium';
}

export const Label = styled('div')<LabelProps>(
  ({
    status,
    isShowAdornment = true,
    borderRounded = false,
    size = 'medium',
    single = false,
    theme,
  }) => {
    const colorConfig = statusColors[status];

    if (!colorConfig) {
      return {
        display: 'none',
      };
    }

    const getWidth = () => {
      if (size === 'medium') {
        return isShowAdornment ? rem(132) : rem(120);
      }
      return rem(85);
    };

    return {
      minWidth: getWidth(),
      height: size === 'small' ? rem(18) : rem(20),
      lineHeight: 1,
      padding: isShowAdornment ? 0 : `${rem(4)}`,
      paddingRight: rem(4),
      borderRadius: borderRounded ? rem(4) : 0,
      fontWeight: weights.semiBold,
      fontSize: rem(12),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: rem(4),
      backgroundColor: colorConfig.background,
      color: colorConfig.color,
      border: `1px solid ${colorConfig.border}`,
      textTransform: 'uppercase',
      '::before': isShowAdornment
        ? {
          content: '""',
          display: 'inline-block',
          width: rem(12),
          height: 'inherit',
          borderRadius: `${rem(4)} 0 0 ${rem(4)}`,
          backgroundColor: colorConfig.color,
        }
        : {},
      '&.border-rounded-start': {
        borderRadius: `${rem(4)} 0 0 ${rem(4)}`,
      },
      '&.border-rounded-end': {
        borderRadius: `0 ${rem(4)} ${rem(4)} 0`,
      },
      '&.border-rounded': {
        borderRadius: rem(4),
      },
      '&:nth-of-type(2n)': {
        borderLeft: single ? `1px solid ${colorConfig.border}` : 'none',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: isShowAdornment ? 'flex-start' : 'center',
      },
    };
  },
);

export const Container = styled('div')({
  maxWidth: rem(286),
  minWidth: rem(206),
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  gap: rem(1),
});

export const Group = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
});
