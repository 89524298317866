import { TIntegrationType } from '@gen2/api/integrations/api';
import { useConnectIntMutation } from '@gen2/api/integrations/hooks';
import { StyledLogo, StyledWrapper } from '@gen2/app/login/Login.styled';
import { useAuth } from '@gen2/hooks';
import { removeStateString } from '@gen2/utils/auth';
import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useIntegrationsStore } from '../store';

const StorageIntegrationCallback = () => {
  const navigate = useNavigate();
  const { initialize } = useAuth();
  const [searchParams] = useSearchParams();
  const { mutate: connectIntMutation } = useConnectIntMutation();
  const { setAlert } = useIntegrationsStore();
  const { protocol, host, pathname } = window.location;
  const { vendor } = useParams<{ vendor: TIntegrationType }>();
  const { t } = useTranslation('integrations');

  const redirectUri = `${protocol}//${host}${pathname}`;

  useEffect(() => {
    if (searchParams.has('error')) {
      removeStateString();
      navigate('/integrations');
      return;
    }

    const code = searchParams.get('code');

    if (code) {
      connectIntMutation(
        {
          code: code,
          redirect_uri: redirectUri,
          type: vendor,
        },
        {
          onSuccess: () => {
            setAlert({
              message: t(`${vendor}.success_connect`),
              severity: 'info',
              open: true,
            });
            initialize();
          },
          onError: (res: unknown) => {
            const response = res as AxiosResponse;

            if (response.status === 403) {
              setAlert({
                message: response.data.message,
                severity: 'error',
                open: true,
              });
            } else {
              setAlert({
                message: t(`${vendor}.error_connect`),
                severity: 'error',
                open: true,
              });
            }
          },
          onSettled: () => {
            navigate(`/integrations/${vendor}`);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledWrapper>
      <StyledLogo />
    </StyledWrapper>
  );
};

export default StorageIntegrationCallback;
