import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LiveFormType } from '@gen2/app/invites/send-invites/store';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { Container, Option, Title } from './create-form-modal.styled';

export type TCreateFormModalProps = {
  fileId: string;
  onClose: () => void;
  open: boolean;
  onSelected: (liveFormType: LiveFormType) => void;
  fileUrl: string;
};

export const TemplateCreateFormModal = ({
  open,
  onClose,
  onSelected,
  fileUrl,
}: TCreateFormModalProps) => {
  return (
    <Dialog
      id="form-fillable-modal"
      open={open}
      onClose={onClose}
      aria-labelledby="form-fillable-modal-title"
      aria-describedby="form-fillable-modal-description"
      data-cy="form-fillable-modal"
    >
      <ModalCloseButton
        data-cy="close-btn"
        aria-label="close"
        onClick={onClose}
      />
      <DialogTitle id="dialog-title">Create Form</DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="dialog-description"
          align="center"
          component="div"
        >
          {
            // If the file is still being processed, show a loading spinner
            !fileUrl ? (
              <>
                <Title>
                  {' '}
                  Please wait while we process this file. As soon as it is
                  completed, you will be able to add fillable fields to this
                  document.
                </Title>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: 1,
                  }}
                >
                  <CircularProgress color="inherit" size={30} />
                </Box>
              </>
            ) : (
              // Otherwise, show the form-fillable options
              <Container>
                <Option>
                  <span>
                    <FontAwesomeIcon icon={duotone('user')} size="3x" />
                  </span>
                  <p>
                    <strong>Individual Form</strong>
                  </p>
                  <p>Each contact will fill and sign their own form</p>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => onSelected('individual')}
                  >
                    Select
                  </Button>
                </Option>
                <Option>
                  <span>
                    <FontAwesomeIcon icon={duotone('users')} size="3x" />
                  </span>
                  <p>
                    <strong>Shared Form</strong>
                  </p>
                  <p>Multiple contacts fill and sign the same form</p>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => onSelected('multi')}
                    size="small"
                  >
                    Select
                  </Button>
                </Option>
              </Container>
            )
          }
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
