import { styled } from '@mui/material';
import { rem } from 'polished';
import { Link } from 'react-router-dom';

// handle text overflow ellipsis
export const StyledLink = styled(Link)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: rem(150),
  display: 'inline-block',

  [theme.breakpoints.up('md')]: {
    width: rem(400),
  },
}));
