import { BorderLinearProgress, ProgressContainer } from './progress.styled';

export const Progress = ({ value }: { value: number }) => {
  return (
    <ProgressContainer>
      {value}%
      <BorderLinearProgress variant="determinate" value={value} />
    </ProgressContainer>
  );
};
