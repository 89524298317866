import { colors, textSizes, weights } from '@components/theme/gen2';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { rem } from 'polished';

interface SidePanelButtonProps {
  draggable?: boolean;
}

export const SidePanelButton = styled('div')<SidePanelButtonProps>(
  ({ draggable = true }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: colors.secondaryDark3,
    fontSize: rem(16),
    cursor: draggable.toString() === 'false' ? 'default' : 'grab',
    gap: rem(10),
    paddingTop: rem(10),
    paddingBottom: rem(10),
    paddingLeft: rem(10),
    paddingRight: rem(10),
    textTransform: 'unset',
    textAlign: 'left',
    verticalAlign: 'middle',
    borderRadius: rem(4),
    backgroundColor: colors.secondaryLight4,
    border: `1px solid ${colors.secondaryLight3}`,
    opacity:
      draggable.toString() === 'false' ? '0.5 !important' : '1 !important',
    marginBottom: rem(10),
    '&:hover': {
      backgroundColor:
        draggable.toString() === 'false'
          ? colors.secondaryLight4
          : colors.secondaryLight3,
      cursor: draggable.toString() === 'false' ? 'not-allowed' : 'grabbing',
    },
  }),
);

export const StyledFormTitle = styled('h6')({
  fontSize: textSizes.base,
  margin: 0,
  fontWeight: weights.medium,
  color: colors.grayDark3,
  textTransform: 'uppercase',
  '& span': {
    color: colors.grayDark1,
    fontWeight: weights.normal,
    textTransform: 'none',
  },
});

export const StyledTypography = styled(Typography)({
  color: colors.grayDark3,
  fontSize: textSizes.base,
  display: 'inline',
  marginTop: rem(-1),
  paddingLeft: rem(5),
});

export const StyledLabel = styled('span')({
  color: colors.grayDark2,
  fontWeight: weights.medium,
  fontSize: textSizes.sm,
  textTransform: 'uppercase',
});
