import { Dialog, styled } from '@mui/material';
import { rem } from 'polished';

export const Container = styled('div')({});

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: rem(550),
    margin: 0,
  },
});

export const StyledContent = styled('div')({
  '& ol': {
    paddingLeft: rem(25),
  },
});
