/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable array-callback-return */
import { cloneDeep, isUndefined } from 'lodash';
import { FormField, ToolbarItem } from 'pspdfkit';
import getAnnotationRenderers from './customAnnotationRenderer';

export const TOOLBAR_ITEMS: Array<ToolbarItem> = [
  { type: 'sidebar-thumbnails', mediaQueries: ['(min-width: 577px)'] },
  { type: 'pager' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  { type: 'search' },
  { type: 'spacer' },
  { type: 'export-pdf' },
  { type: 'print', mediaQueries: ['(min-width: 577px)'] },
];

export const handleAnnotationCreation = async (
  instance: any,
  annotation: any,
  mySignatureIdsRef: any,
  setSignatureAnnotationIds: any,
  myEmail: string,
) => {
  if (annotation.isSignature) {
    for (let i = 0; i < instance.totalPageCount; i++) {
      const annotations = await instance.getAnnotations(i);
      for await (const maybeCorrectAnnotation of annotations) {
        if (
          annotation.boundingBox.isRectOverlapping(
            maybeCorrectAnnotation.boundingBox,
          )
        ) {
          const newAnnotation = getAnnotationRenderers({
            annotation: maybeCorrectAnnotation,
          });
          if (newAnnotation?.node) {
            newAnnotation.node.className = 'signed';
          }
        }
      }
    }
    const signatures = [...mySignatureIdsRef.current, annotation.id];
    setSignatureAnnotationIds(signatures);
    mySignatureIdsRef.current = signatures;
  }
};

export const handleAnnotationDelete = async (
  instance: any,
  annotation: any,
  myEmail: string,
) => {
  if (annotation.isSignature) {
    for (let i = 0; i < instance.totalPageCount; i++) {
      const annotations = await instance.getAnnotations(i);
      for await (const maybeCorrectAnnotation of annotations) {
        if (
          annotation.boundingBox.isRectOverlapping(
            maybeCorrectAnnotation.boundingBox,
          )
        ) {
          const newAnnotation = getAnnotationRenderers({
            annotation: maybeCorrectAnnotation,
          });
          if (newAnnotation?.node) {
            newAnnotation.node.className = '';
          }
        }
      }
    }
  }
};

export const updateAnnotationIdsBasedOnPdfObjectId = (
  originalInstantJSON: any,
) => {
  const instantJSON = cloneDeep(originalInstantJSON);
  instantJSON.annotations.forEach((annotation: any) => {
    // Check if `pdfObjectId` exists
    if (
      (!isUndefined(annotation.pdfObjectId) &&
        typeof annotation.pdfObjectId === 'string') ||
      typeof annotation.pdfObjectId === 'number'
    ) {
      // Update the annotation ID with pdfObjectId
      annotation.id = annotation.pdfObjectId.toString();
    }
  });

  return instantJSON;
};

export const generateInstantJSON = async (instance: any, PSPDFKit: any) => {
  if (!instance) {
    throw new Error('PSPDFKit instance is required');
  }

  const annotations = [
    ...(
      await Promise.all(
        Array.from({ length: instance.totalPageCount }).map((_, pageIndex) =>
          instance.getAnnotations(pageIndex),
        ),
      )
    ).flatMap((annotations) =>
      annotations.reduce((acc: any[], annotation: any) => {
        const serialized =
          PSPDFKit.Annotations.toSerializableObject(annotation);
        return acc.concat(serialized);
      }, []),
    ),
  ];

  const formFields = [
    ...(await instance.getFormFields()).reduce(
      (acc: any[], formField: FormField) =>
        acc.concat(PSPDFKit.FormFields.toSerializableObject(formField)),
      [],
    ),
  ];

  const formFieldValues = [
    ...Object.entries(instance.getFormFieldValues())

      // @ts-ignore
      .filter((e) => {
        if (
          null !== e[1] &&
          '' !== e[1] &&
          // @ts-ignore
          0 !== e[1].length &&
          // @ts-ignore
          !e[1].includes('Off')
        )
          return e;
      })
      .reduce((acc, ffValue) => {
        const allValues = {
          name: ffValue[0],
          value: ffValue[1],
          type: 'pspdfkit/form-field-value',
          v: 1,
        };
        // @ts-ignore
        return acc.concat(allValues);
      }, []),
  ];
  return {
    format: 'https://pspdfkit.com/instant-json/v1',
    annotations,
    formFields,
    formFieldValues,
  };
};
