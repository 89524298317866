import { SearchCondition } from '@gen2/types/search-condition';
import { TTeam } from '@gen2/types/team';
import { TUser } from '@gen2/types/user';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { get, has } from 'lodash';
import {
  createTeam,
  deleteTeam,
  getMembersInTeam,
  getTeam,
  getTeamList,
  removeMemberFromTeam,
  updateTeam,
} from './api';

export enum Keys {
  queryTeamList = 'getTeamList',
  queryTeam = 'getTeam',
  queryMembersInTeam = 'getMembersInTeam',
}

export const useTeamListQuery = (search: SearchCondition) =>
  useQuery({
    queryKey: [Keys.queryTeamList, search],
    queryFn: async () => {
      const { data } = await getTeamList({
        ...search,
        page: search.page,
        per_page: search.per_page,
      });

      // return true means there is no data in database, default is false
      let hasNoData = false;

      if (data && has(data, 'data')) {
        const teams = data.data.teams as TTeam[];

        // check if search filter is not empty
        const hasSearchFilter =
          get(search, 'filter[search]', '').trim().length > 0;

        // if teams is empty and search filter is empty, hasNoData is true, means no data
        // if teams is empty and search filter is not empty, hasNoData is false, means no search results
        if (teams.length === 0) {
          hasNoData = !hasSearchFilter;
        }

        return {
          data: teams,
          links: data.links,
          meta: data.meta,
          hasNoData, // flag is for teams to differentiate between no data and no search results, false by default
        };
      }
      return null;
    },
  });

export const useTeamQuery = (teamId: string) =>
  useQuery({
    queryKey: [Keys.queryTeam, teamId],
    queryFn: async () => {
      const { data } = await getTeam(teamId);

      if (data && has(data, 'data')) {
        return data.data as TTeam;
      }
      return null;
    },
    enabled: !!teamId,
    onError: (err) => {
      const error = err as AxiosResponse;

      if (error.status === 404) {
        window.location.href = "/404";
      }
    }
  });

export const useCreateTeamMutation = () =>
  useMutation({ mutationFn: createTeam });

export const useUpdateTeamMutation = () =>
  useMutation({ mutationFn: updateTeam });

export const useDeleteTeamMutation = () =>
  useMutation({ mutationFn: deleteTeam });

export const useMembersInTeamListQuery = (
  search: SearchCondition,
  teamId?: string,
) =>
  useQuery({
    queryKey: [Keys.queryMembersInTeam, { search, teamId: teamId }],
    queryFn: async () => {
      if (!teamId) {
        return null;
      }
      const { data } = await getMembersInTeam(search, teamId);

      if (data && has(data, 'data')) {
        return {
          data: data.data.users as TUser[],
          links: data.links,
          meta: data.meta,
        };
      }
      return null;
    },
  });

export const useRemoveMemberFromTeamMutation = () =>
  useMutation({ mutationFn: removeMemberFromTeam });
