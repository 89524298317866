import { TInviteIndicator } from '@gen2/api/invites/api';
import { TContact } from '@gen2/types/contact';
import { TTeam } from "@gen2/types/team";
import { TUser } from '@gen2/types/user';
import { create } from 'zustand';

export type TInviteListingStoreFilter = {
  anchorEl: HTMLElement | null;
  selectedContacts: TContact[] | [];
  selectedUsers: TUser[] | [];
  selectedAssignees: TUser[] | [];
  selectedAssignedTeams: TTeam[] | [];
  selectedInviteIndicators: TInviteIndicator[] | [];
  clearFlag: boolean;
  applyFilter: ({
    selectedUsers,
    selectedContacts,
    selectedAssignees,
    selectedAssignedTeams,
    selectedInviteIndicators,
  }: {
    selectedUsers: TUser[];
    selectedContacts: TContact[];
    selectedAssignees: TUser[];
    selectedAssignedTeams: TTeam[];
    selectedInviteIndicators: TInviteIndicator[];
  }) => void;
  clearFilter: () => void;

  openFilter: (event: React.MouseEvent<HTMLElement>) => void;
  closeFilter: () => void;
  resetClearFlag: () => void;
};

export type TInviteListingStore = {
  archive: {
    isOpen: boolean;
    subject: string;
    id: string;
  };
  filter: TInviteListingStoreFilter;

  setArchive: ({
    isOpen,
    subject,
  }: {
    isOpen: boolean;
    subject: string;
    id: string;
  }) => void;
};

export const useInviteListingStore = create<TInviteListingStore>()((set) => ({
  archive: {
    isOpen: false,
    subject: '',
    id: '',
  },
  filter: {
    anchorEl: null,
    selectedContacts: [],
    selectedUsers: [],
    selectedAssignees: [],
    selectedAssignedTeams: [],
    selectedInviteIndicators: [],
    clearFlag: false,
    applyFilter: ({ selectedUsers, selectedContacts, selectedAssignees, selectedAssignedTeams, selectedInviteIndicators}) => {
      set((state) => ({
        filter: {
          ...state.filter,
          selectedUsers,
          selectedContacts,
          selectedAssignees,
          selectedAssignedTeams,
          selectedInviteIndicators,
        },
      }));
    },
    clearFilter: () => {
      set((state) => ({
        filter: {
          ...state.filter,
          clearFlag: true,
          selectedUsers: [],
          selectedContacts: [],
          selectedAssignees: [],
          selectedAssignedTeams: [],
          selectedInviteIndicators: [],
        },
      }));
    },
    openFilter: (event) => {
      set((state) => ({
        filter: {
          ...state.filter,
          anchorEl: event.currentTarget,
        },
      }));
    },
    closeFilter: () => {
      set((state) => ({
        filter: {
          ...state.filter,
          anchorEl: null,
        },
      }));
    },
    resetClearFlag: () => {
      set((state) => ({
        filter: {
          ...state.filter,
          clearFlag: false
        },
      }));
    },
  },

  // actions
  setArchive: ({ isOpen, subject, id }) => {
    set({
      archive: {
        isOpen,
        subject,
        id,
      },
    });
  },
}));
