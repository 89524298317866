import { colors, textSizes, weights } from '@components/theme/gen2';
import { Box, styled } from '@mui/material';
import { rem } from 'polished';
import { Link } from 'react-router-dom';

export const StyledH3 = styled('h3')({
  fontSize: rem(20),
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 600,
  color: colors.grayDark3,
});

export const StyledP = styled('p')({
  fontWeight: 400,
  color: colors.grayDark2,
});

export const ToastContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(15),
  lineHeight: rem(21),
  fontSize: textSizes.base
});

export const StyledLink = styled(Link)({
  fontWeight: weights.medium,
  color: '#004C8B'
});
