import { colors } from '@components/theme/gen2';
import { styled, Popover } from '@mui/material';

import { rem } from 'polished';

// MuiPopover-paper
export const StyledPopover = styled(Popover)({
  '& .MuiPopover-paper': {
    overflow: 'visible',
  },
});

export const Container = styled('div')({
  width: rem(644),
  minHeight: rem(508),
  background: colors.white,
  borderRadius: rem(4),
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  overflowX: 'hidden',
});

export const Content = styled('div')({
  width: '100%',
  height: rem(456),
  maxHeight: rem(456),
  display: 'flex',
});

interface ActionsProps {
  hasLeftActionButton?: boolean;
}

export const Actions = styled('div')<ActionsProps>(
  ({ hasLeftActionButton = false }) => ({
    padding: `${rem(8)} ${rem(12)}`,
    background: colors.grayLight3,
    display: 'flex',
    gap: rem(10),
    alignItems: 'center',
    justifyContent: hasLeftActionButton ? 'space-between' : 'flex-end',
    minHeight: rem(52),
  }),
);

export const Tabs = styled('div')({
  width: rem(195),
  background: colors.grayLight3,
  paddingLeft: rem(12),
  paddingTop: rem(24),
});

export const Title = styled('span')({
  display: 'block',
  fontSize: rem(12),
  fontWeight: 600,
  color: colors.grayDark2,
  textTransform: 'uppercase',
  marginBottom: rem(12),
});

export const Tab = styled('div')<{ active: boolean }>(({ active }) => ({
  minWidth: rem(140),
  padding: rem(12),
  fontSize: rem(14),
  fontWeight: 500,
  color: active ? colors.blueDark2 : colors.grayDark2,
  backgroundColor: active ? colors.blueLight4 : colors.grayLight3,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const TabPanel = styled('div')({
  flex: '1 1 auto',
  background: colors.white,
  padding: `${rem(24)} ${rem(20)}`,
  overflow: 'hidden',
});

export const Heading = styled('div')({
  fontSize: rem(15),
  fontWeight: 600,
  color: colors.grayDark3,
  marginBottom: rem(12),
  '> span': {
    display: 'block',
    fontSize: rem(14),
    fontWeight: 400,
    color: colors.grayDark3,
  },
});

export const List = styled('div')({
  marginTop: rem(12),
  paddingTop: rem(12),
  maxHeight: rem(300),
  overflow: 'auto',
});

export const Item = styled('div')<{ disabled?: boolean }>(
  ({ disabled = false }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${rem(4)} ${rem(12)}`,
    borderBottom: `1px solid ${colors.grayLight3}`,
    cursor: disabled ? 'not-allowed' : 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.5 : 1,
  }),
);

export const StyledEmpty = styled('div')({
  display: 'flex',
  padding: rem(20),
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: rem(300),
  color: colors.grayDark3,
});
