import axios from '@gen2/api/axios.request';
import { config } from '@gen2/config';
import { SearchCondition } from '@gen2/types/search-condition';
import { AxiosPromise } from 'axios';

export interface ITeamForm {
  name: string;
  description: string;
  user_ids: string[];
  id?: string;
}

export const getTeamList = (
  search: SearchCondition,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/teams`,
    params: {
      ...search,
    },
    method: 'get',
  });
};

export const createTeam = (
  form: ITeamForm,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/teams`,
    data: form,
    method: 'post',
  });
};

export const updateTeam = (
  form: ITeamForm,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/teams/${form.id}`,
    data: form,
    method: 'put',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTeam = (teamId: string): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/teams/${teamId}`,
    method: 'get',
  });

export const deleteTeam = (
  teamIds: string[],
): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/teams`,
    data: { team_ids: teamIds },
    method: 'delete',
  });
};

export const getMembersInTeam = (
  search: SearchCondition,
  teamId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/teams/${teamId}/users`,
    params: {
      ...search,
    },
    method: 'get',
  });
};

export interface IRemoveTeamPayload {
  teamId: string;
  memberId: string;
}

export const removeMemberFromTeam = ({
  teamId,
  memberId,
}: IRemoveTeamPayload): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/teams/${teamId}/users/${memberId}`,
    method: 'delete',
  });
};
