import { colors, headingType, weights } from '@components/theme/gen2';
import TagsDropdown from '@gen2/app/components/tags-dropdown/tags-dropdown';
import { Box, IconButton, styled } from '@mui/material';
import { rem } from 'polished';
import { SendToLine } from '../send-to-line/send-to-line';

export interface SidebarControlProps {
  $leftSidebarOpen: boolean;
  $rightSidebarOpen?: boolean;
}

export const StyledLayout = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: `calc(100% - var(--sidebar-header-height) - var(--footer-height))`,
  },
}));

export const StyledBox = styled('div')({
  position: 'relative',
  height: '100%',
  flex: 1,
});

export const StyledMessageBoxContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - var(--header-height))',
  position: 'absolute',
  backgroundColor: 'lightgray',
  zIndex: 200,
  left: 0,
  top: 0,
  display: 'block',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - var(--header-height) - var(--sidebar-header-height))',
  },
}));

export const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  top: rem(10),
  right: rem(15),
  width: rem(30),
  height: rem(30),
  backgroundColor: colors.grayDark4,
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.grayDark3,
  },
});

export const StyledMain = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'inherit',
  flexGrow: 'inherit',
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - var(--header-height) - var(--footer-height) - var(--sidebar-header-height))`,
  },
}));

export const StyledHeader = styled('div')({
  flex: '0 0 auto',
  minHeight: rem(154),
  background: colors.white,
  borderBottom: `1px solid ${colors.grayLight1}`,
});

export const StyledBody = styled('div')(({ theme }) => ({
  // display: 'flex',
  // justifyContent: 'center',
  padding: rem(50),
  // flex: '1 1 auto',
  background: colors.grayLight2,
  display: 'block',
  // maxHeight: 'calc(100vh - 154px - 60px)',
  overflowY: 'auto',
  [theme.breakpoints.down('xl')]: {
    padding: rem(35),
  },
  [theme.breakpoints.down('md')]: {
    padding: rem(20),
    maxHeight: 'fit-content',
  },
}));

export const ActionBar = styled('div')<SidebarControlProps>(
  ({ $leftSidebarOpen, $rightSidebarOpen, theme }) => ({
    display: 'flex',
    padding: rem(20),
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.grayLight1}`,
    borderRight: `1px solid ${colors.grayLight1}`,
    [theme.breakpoints.up('xs')]: {
      height: rem(60),

      '& > section > h4': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: rem(380),
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > section > h4': {
        maxWidth: rem(650),
        ...(($leftSidebarOpen || $rightSidebarOpen) && {
          maxWidth: rem(400),
        }),
      },
    },
    [theme.breakpoints.up('xl')]: {
      '& > section > h4': {
        maxWidth: rem(800),
        ...(($leftSidebarOpen || $rightSidebarOpen) && {
          maxWidth: rem(500),
        }),
      },
    },
    [theme.breakpoints.up(1440)]: {
      '& > section > h4': {
        maxWidth: 'unset',
        ...(($leftSidebarOpen || $rightSidebarOpen) && {
          maxWidth: rem(650),
        }),
      },
    },
    [theme.breakpoints.up(1536)]: {
      padding: `${rem(10)} ${rem(30)} ${rem(10)} ${rem(50)}`,
      '& > section > h4': {
        maxWidth: 'unset',
      },
    },
    '> section': {
      display: 'flex',
      alignItems: 'center',
      columnGap: rem(10),
      '> a': {
        color: colors.grayDark3,
      },
      '> h4': {
        margin: 0,
        fontSize: rem(18),
        fontWeight: weights.semiBold,
        color: colors.grayDark3,
        fontFamily: headingType,
      },
    },
    //placeholder
    '> div': {
      width: rem(477),
    },
  }),
);

export const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: rem(88),
  width: 'inherit',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const FlexBox = styled('div')({
  flex: `1 1 auto`,
  borderRight: `1px solid ${colors.grayLight1}`,
});

export const SendTo = styled(SendToLine)(({ theme }) => ({
  minHeight: rem(70),
  width: '100%',
  borderBottom: `1px solid ${colors.grayLight1}`,
  flexGrow: 0,
  padding: rem(20),

  [theme.breakpoints.up(1536)]: {
    padding: `${rem(10)} ${rem(50)}`,
  },

  '& > p': {
    whiteSpace: 'nowrap',
  },

  '& .MuiChip-label': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexDirection: 'column',
    maxWidth: rem(200),

    [theme.breakpoints.up('xl')]: {
      maxWidth: rem(400),
    },
  },
}));

export const PointOfContact = styled(TagsDropdown)(({ theme }) => ({
  width: '50%',
  minHeight: rem(45),
  borderRight: `1px solid ${colors.grayLight1}`,
  flexDirection: 'row',
  padding: rem(20),
  marginLeft: 0,

  '& .MuiStack-root': {
    marginLeft: 0,
  },

  [theme.breakpoints.up(1536)]: {
    padding: `${rem(23)} ${rem(50)}`,
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    borderBottom: `1px solid ${colors.grayLight1}`,
    flexDirection: 'row',
    verticalAlign: 'center',

    '& .MuiStack-root': {
      marginLeft: 0,
      alignSelf: 'center',
    },
  },
}));

export const POCNameBox = styled(Box)<SidebarControlProps>(
  ({ $leftSidebarOpen, $rightSidebarOpen, theme }) => ({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: rem(200),

    '& a': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: rem(200),
      ...(($leftSidebarOpen || $rightSidebarOpen) && {
        maxWidth: rem(100),
      }),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: rem(220),
      ...(($leftSidebarOpen || $rightSidebarOpen) && {
        maxWidth: rem(100),
      }),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: rem(110),
      ...(($leftSidebarOpen || $rightSidebarOpen) && {
        maxWidth: rem(150),
      }),
    },
    [theme.breakpoints.up(1440)]: {
      maxWidth: rem(190),
      ...(($leftSidebarOpen || $rightSidebarOpen) && {
        maxWidth: rem(220),
      }),
    },
    [theme.breakpoints.up(1536)]: {
      maxWidth: rem(450),
      ...(($leftSidebarOpen || $rightSidebarOpen) && {
        maxWidth: rem(240),
      }),
    },
    [theme.breakpoints.up(2560)]: {
      maxWidth: 'unset',
    },
  }),
);

export const Reminders = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: rem(20),
  justifyContent: 'space-between',
  flex: 1,
  '&:has(div:nth-child(3))': {
    flexGrow: 1,
    maxWidth: rem(400),
  },
  [theme.breakpoints.down('sm')]: {
    padding: rem(10),
    flex: '1 1 auto',
  },
  [theme.breakpoints.down(1025)]: {
    justifyContent: 'space-evenly',
  },
  [theme.breakpoints.up('xl')]: {
    flex: `1 0 ${rem(360)}`,
    justifyContent: 'space-around',
  },
}));

export const DateFrame = styled('div')({
  '> h6': {
    margin: rem(3),
    fontSize: rem(15),
    fontWeight: weights.semiBold,
    color: colors.grayDark2,
  },
});

export const LoadingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    height: rem(300),
  },
}));

export const StyledHeaderContent = styled('div')<SidebarControlProps>(
  ({ $leftSidebarOpen, $rightSidebarOpen, theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',

      ...(($leftSidebarOpen || $rightSidebarOpen) && {
        flexDirection: 'column',
      }),
    },

    [theme.breakpoints.up(1536)]: {
      ...(($leftSidebarOpen || $rightSidebarOpen) && {
        flexDirection: 'row',
      }),
    },
  }),
);

export const StyledRightHeaderContent = styled('div')<SidebarControlProps>(
  ({ $leftSidebarOpen, $rightSidebarOpen, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `${rem(10)} ${rem(20)} `,
    gap: rem(43),
    paddingRight: 0,
    borderTop: `1px solid ${colors.grayLight1}`,

    [theme.breakpoints.up('xl')]: {
      flexDirection: 'column',

      ...(($leftSidebarOpen || $rightSidebarOpen) && {
        borderTop: `1px solid ${colors.grayLight1}`,
        flexDirection: 'row',
      }),
    },

    [theme.breakpoints.up(1536)]: {
      padding: `${rem(10)} ${rem(30)} ${rem(10)} ${rem(50)}`,
      borderTop: 'none',
      ...(($leftSidebarOpen || $rightSidebarOpen) && {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        minWidth: rem(467),
        gap: rem(20),
        padding: rem(20),
        borderTop: 'none',
      }),
    },
  }),
);
