import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TIntegrationType } from '@gen2/api/integrations/api';
import { ActionModal } from '@gen2/app/components/action-modal/action-modal';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { useAuth } from '@gen2/hooks';
import { ButtonPropsVariantOverrides, Tooltip } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledButton,
  StyledCardButtonContainer,
  StyledContent,
  StyledIntCard,
  StyledIntCardContent,
  StyledIntCardHeader,
  StyledIntCardImg,
  StyledIntCardLevel,
  StyledIntCards,
  StyledIntegrations,
  StyledTitle,
} from './integrations.styled';
import { useIntegrationContext } from './useIntegrationContext';

interface IIntegration {
  provider: TIntegrationType;
  title: string;
  img: string;
  link: string;
  connected: boolean;
  disabled: boolean;
  cardDataCy: string;
  buttonDataCy: string;
  buttonVariant: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  level: 'user' | 'account';
  hidden: boolean;
}

const Integrations = () => {
  const { disabled, connect, disconnect, isLoading, isConnected } =
    useIntegrationContext();
  const { user, featureFlags } = useAuth();
  const { t } = useTranslation('integrations');

  const { showModal } = useActionModalStore();

  const handleSwitchChange = async (type: TIntegrationType) => {
    if (!isConnected(type)) {
      connect(type);
    } else {
      const provider = t(`vendors.${type}`);

      const { isConfirmed } = await showModal({
        header: t('disconnect.modal.header', { provider }),
        message: 'disconnect.modal.message',
        messageParams: { provider },
        translationNamespace: 'integrations',
        closeButtonLabel: t('disconnect.modal.cancel_btn') ?? '',
        submitButtonLabel: t('disconnect.modal.confirm_btn') ?? '',
      });

      if (!isConfirmed) {
        return;
      }

      disconnect(type);
    }
  };

  const integrations = useMemo<IIntegration[]>(
    () => [
      {
        provider: 'google',
        title: t('google.thumbnail.title'),
        img: '/assets/int-google.png',
        link: '/integrations/google',
        connected: !!user?.integrations?.drive?.is_connected,
        disabled: disabled.google,
        cardDataCy: 'gdrive-link',
        buttonDataCy: 'connect-google-drive',
        buttonVariant: user?.integrations?.drive?.is_connected
          ? 'outlined'
          : 'contained',
        level: 'user',
        hidden: false,
      },
      {
        provider: 'microsoft',
        title: t('microsoft.thumbnail.title'),
        img: '/assets/int-microsoft.png',
        link: '/integrations/microsoft',
        connected: !!user?.integrations?.onedrive?.is_connected,
        disabled: disabled.microsoft,
        cardDataCy: 'onedrive-link',
        buttonDataCy: 'connect-one-drive',
        buttonVariant: user?.integrations?.onedrive?.is_connected
          ? 'outlined'
          : 'contained',
        level: 'user',
        hidden: false,
      },
      {
        provider: 'sharepoint',
        title: t('microsoft.thumbnail.title'),
        img: '/assets/int-sharepoint.png',
        link: '/integrations/sharepoint',
        connected:
          !!user?.organisations[0].accounts[0]?.integrations?.sharepoint
            ?.is_connected,
        disabled: disabled.sharepoint,
        cardDataCy: 'sharepoint-link',
        buttonDataCy: 'connect-sharepoint',
        buttonVariant: user?.organisations[0].accounts[0]?.integrations
          ?.sharepoint?.is_connected
          ? 'outlined'
          : 'contained',
        level: 'account',
        hidden: !featureFlags['sharepoint']
      },
    ],
    [
      disabled.google,
      disabled.microsoft,
      t,
      user?.integrations?.drive?.is_connected,
      user?.integrations?.onedrive?.is_connected,
      user?.organisations[0].accounts[0]?.integrations?.sharepoint
        ?.is_connected,
      featureFlags,
    ],
  );

  return (
    <StyledIntegrations>
      <StyledContent>
        <StyledTitle>Cloud Storage</StyledTitle>
        <StyledIntCards>
          {integrations.map(
            (integration, index) =>
              !integration.hidden && (
                <StyledIntCard
                  key={index}
                  data-cy={integration.cardDataCy}
                  to={integration.link}
                >
                  <StyledIntCardHeader>
                    <StyledIntCardImg src={integration.img} alt="" />
                  </StyledIntCardHeader>
                  <StyledIntCardContent>
                    <p>{integration.title}</p>

                    <StyledCardButtonContainer>
                      <StyledButton
                        variant={integration.buttonVariant}
                        color="secondary"
                        size="small"
                        onClick={() => handleSwitchChange(integration.provider)}
                        loading={isLoading}
                        disabled={isLoading || integration.disabled}
                        data-cy={integration.buttonDataCy}
                      >
                        {integration.connected ? (
                          <>
                            <FontAwesomeIcon icon={regular('link-slash')} />
                            <span>Disconnect</span>
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={regular('link')} />
                            <span>Connect</span>
                          </>
                        )}
                      </StyledButton>
                      <Tooltip title={t(`tooltip.level.${integration.level}`)}>
                        <StyledIntCardLevel>
                          <FontAwesomeIcon
                            icon={
                              integration.level === 'user'
                                ? solid('user')
                                : solid('users')
                            }
                          />
                        </StyledIntCardLevel>
                      </Tooltip>
                    </StyledCardButtonContainer>
                  </StyledIntCardContent>
                </StyledIntCard>
              ),
          )}
        </StyledIntCards>
      </StyledContent>

      <ActionModal />
    </StyledIntegrations>
  );
};

export default Integrations;
